import React from "react";
import { Typography, Box } from "@mui/material";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import Fab from "@mui/material/Fab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import data from "./dataItems";

export default function ResponsiveCarousel(props) {
  const ref = React.useRef();
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        marginTop: "10px",
        padding: "10px 0 10px 0",
      }}
    >
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          let currentVisibleSlide = 5;
          if (parentWidth <= 1440) currentVisibleSlide = 5;
          if (parentWidth <= 1080) currentVisibleSlide = 3;
          if (parentWidth <= 700) currentVisibleSlide = 1;
          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={Card}
              slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
              carouselWidth={parentWidth}
              data={data}
              currentVisibleSlide={currentVisibleSlide}
              maxVisibleSlide={5}
              useGrabCursor
            />
          );
        }}
      />
      <>
        <Fab
          className="responsive_fontsize16"
          sx={{
            position: "absolute",
            top: "40%",
            left: 10,
            zIndex: 10,
            backgroundColor: "#ffffff",
            height: { xs: "18px", sm: "25px", md: "40px" },
            width: { xs: "18px", sm: "25px", md: "40px" },
          }}
          size="small"
          color="primary"
          onClick={() => {
            ref.current?.goBack();
          }}
        >
          <ArrowBackIcon className="responsive_fontsize24" />
        </Fab>
        <Fab
          sx={{
            position: "absolute",
            top: "40%",
            right: 10,
            zIndex: 10,
            backgroundColor: "#ffffff",
            height: { xs: "18px", sm: "25px", md: "40px" },
            width: { xs: "18px", sm: "25px", md: "40px" },
          }}
          size="small"
          color="primary"
          onClick={() => {
            ref.current?.goNext(6);
          }}
        >
          <ArrowForwardIcon className="responsive_fontsize24" />
        </Fab>
      </>
    </div>
  );
}

const Card = React.memo(function (props) {
  const { data, dataIndex } = props;
  const {
    image,
    detail,
    subcategory,
    eventStartDate,
    eventEndDate,
    eventStartTime,
    eventEndTime,
  } = data[dataIndex];
  return (
    <Box
      sx={{
        width: "100%",
        height: 300,
        position: "relative",
        userSelect: "none",
      }}
      className="my-slide-component"
    >
      <img
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
          borderRadius: 0,
        }}
        draggable={false}
        src={image}
        alt="img"
      />
      <Box
        sx={{
          p: 2,
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          background: "rgba(38, 29, 29, 0.5)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ textAlign: "left", color: "#ffffff" }}>
            <Typography
              className="responsive_fontsize20"
              variant="subtitle1"
              sx={{
                my: 0,
                fontWeight: "semi-bold",
              }}
            >
              {subcategory[0]}
            </Typography>
            <Typography
              className="responsive_fontsize12"
              sx={{ fontWeight: "300" }}
            >
              {detail}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "right", color: "#ffffff" }}>
            <Typography
              className="responsive_fontsize12"
              sx={{ fontWeight: "300" }}
            >
              Event Date: {eventStartDate} to {eventEndDate}
            </Typography>
            <Typography
              className="responsive_fontsize12"
              sx={{ fontWeight: "300" }}
            >
              Start at: {eventStartTime} till {eventEndTime}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
