import React from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { addItem } from "src/redux/slices/cartSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function FoodCard({ foodItem, subcategory }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAddToCart = () => {
    const itemToAdd = {
      ...foodItem,
      subcategory: subcategory,
    };
    dispatch(addItem(itemToAdd));
    toast.success(`Added ${subcategory} to your cart`);
  };

  const handleDesClick = () => {
    navigate("/description", { state: { foodItem } });
  };

  return (
    <Card
     
      sx={{
        width: {
          xs: "330px",
          sm: "260px",
          md: "300px",
          lg: "320px",
          xl: "330px",
        },
        height: {
          xs: "350px",
          sm: "320px",
          md: "330px",
          lg: "340px",
          xl: "350px",
        },
        position: "relative",
        backgroundColor: "#261d1d",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent sx={{ padding: 0 }}>
        <img
         onClick={handleDesClick}
          src={foodItem.image}
          alt="Product"
          style={{
            width: "100%",
            height: "230px",
            objectFit: "cover",
          }}
        />
        <Box sx={{ padding: "5px 12px 0px 12px" }}>
          <Typography
            className="responsive_fontsize20"
            variant="subtitle1"
            sx={{
              my: 0,
              color: "#ffffff",
              fontWeight: "semi-bold",
            }}
          >
            {subcategory}
          </Typography>
          <Typography
            className="responsive_fontsize12"
            sx={{
              color: "#ffffff",
              fontWeight: "300",
            }}
          >
            {foodItem.detail}
          </Typography>
        </Box>
        <Typography
          className="responsive_fontsize20"
          sx={{
            padding: "4px",
            position: "absolute",
            bottom: "8px",
            left: "8px",
            textAlign: "center",
            color: "#EAA017",
          }}
        >
          {foodItem.price}
        </Typography>
        <Button
          className="responsive_fontsize16"
          variant="contained"
          onClick={handleAddToCart}
          sx={{
            paddingTop: "1px",
            paddingBottom: "1px",
            position: "absolute",
            bottom: "14px",
            right: "8px",
            textTransform: "capitalize",
            borderRadius: "5px",
            color: "#000000",
            height: "1.5rem",
            backgroundColor: "#0ff34f",
            "&:hover": {
              backgroundColor: "#8e5a00",
            },
          }}
        >
          Add to Cart
        </Button>
      </CardContent>
    </Card>
  );
}

export default FoodCard;
