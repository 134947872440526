import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeItem,
  incrementQuantity,
  decrementQuantity,
} from "../../redux/slices/cartSlice";
import {
  Box,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import OrderConfirmationItems from "./order_confirmation_item";
import CheckoutPayment from "./checkout_payment";
import ButtonComponent from "../ui/buttons/button_component";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import logo from "../../assets/logo.jpg";
import ConfirmationAlert from "./dialog_confirm/confirmation_alert";
import {
  clearCart,
  clearOrderList,
  clearOrderedItems,
  confirmOrder,
} from "../../redux/actions/cartAction";
import DeliveryAddress from "./dialog_confirm/delivery_address";

const DELIVERY_CHARGE_FREE_DISTANCE = 4; // km
const DELIVERY_CHARGE = 50; // Rs.
const BBG_COORDINATES = { lat: 27.6663, lng: 85.3330 }; // Example coordinates for BBG

function CheckoutSection() {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderedList = useSelector((state) => state.cart.items);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [deviceCoordinates, setDeviceCoordinates] = useState(null);

  useEffect(() => {
    const totalCount = orderedList.reduce(
      (total, item) => total + item.quantity,
      0
    );
    setTotalItemCount(totalCount);
  }, [orderedList]);

  const handleDistanceCalculation = useCallback((deviceCoords) => {
    if (selectedAddress) {
      const distance = calculateDistance(
        deviceCoords.lat,
        deviceCoords.lng,
        BBG_COORDINATES.lat,
        BBG_COORDINATES.lng
      );
      console.log("User location:", deviceCoords);
      console.log("BBG location:", BBG_COORDINATES);
      console.log(`Distance calculated: ${distance} km`);
      const charge = distance <= DELIVERY_CHARGE_FREE_DISTANCE ? 0 : DELIVERY_CHARGE;
      console.log(`Delivery charge: ${charge} Rs.`);
      setDeliveryCharge(charge);
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (selectedAddress && deviceCoordinates) {
      handleDistanceCalculation(deviceCoordinates);
    }
  }, [selectedAddress, deviceCoordinates, handleDistanceCalculation]);

  const handleConfirmOrder = () => {
    console.log("Order being confirmed with delivery charge:", deliveryCharge);
    setOpenConfirmationDialog(true);
    dispatch(confirmOrder());
    dispatch(clearCart());
    dispatch(clearOrderList());
    dispatch(clearOrderedItems());
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleRemoveItem = (id) => {
    dispatch(removeItem(id));
  };

  const handleIncrement = (id) => {
    dispatch(incrementQuantity(id));
  };

  const handleDecrement = (id) => {
    dispatch(decrementQuantity(id));
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  const handleDeviceCoordinatesUpdate = (coords) => {
    setDeviceCoordinates(coords);
  };

  const handleAddressUpdate = (address) => {
    setSelectedAddress(address);
  };

  const calculateTotalPrice = () => {
    return orderedList
      .reduce((total, item) => {
        const price = parseFloat(item.price[1]);
        return total + price * item.quantity;
      }, 0)
      .toFixed(2);
  };

  const totalAmount = () => {
    return (parseFloat(calculateTotalPrice()) + deliveryCharge).toFixed(2);
  };

  return (
    <>
      <Box
        sx={{
          mt: 10,
          display: "flex",
          flexDirection: "column",
          padding: { xs: "2rem 4rem", md: "2rem 10rem" },
          background: "#FCFCFC",
        }}
      >
        <Box sx={{ padding: "1rem", display: "flex", flexDirection: "column" }}>
          <Typography
            className="responsive_fontsize32"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "roboto serif",
              fontWeight: "600",
            }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ height: "45px", width: "45px", marginRight: "1rem" }}
            />
            ORDER INVOICE
          </Typography>
        </Box>

        <Box sx={{ padding: "1rem 0", display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              className="responsive_fontsize24"
              sx={{ mb: "0.8rem", color: "green" }}
            >
              Order List
            </Typography>
            <Typography className="responsive_fontsize20" sx={{ mb: "0.8rem" }}>
              Total Items: {totalItemCount}
            </Typography>
          </Box>
          <Divider sx={{ mb: "1rem" }} />
          <Box>
            <OrderConfirmationItems
              orderedList={orderedList}
              handleRemoveItem={handleRemoveItem}
              handleIncrement={handleIncrement}
              handleDecrement={handleDecrement}
            />
          </Box>
        </Box>

        <Box>
          <Typography
            className="responsive_fontsize24"
            sx={{ mb: "0.8rem", color: "green" }}
          >
            Delivery Address
          </Typography>
          <Divider sx={{ mb: "1rem" }} />
          <Box>
            <DeliveryAddress
              onAddressUpdate={handleAddressUpdate}
              onDistanceCalculated={handleDeviceCoordinatesUpdate}
            />
          </Box>
        </Box>

        <Box
          sx={{
            padding: "1rem 2rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            className="responsive_fontsize24"
            sx={{ mb: "0.8rem", color: "green" }}
          >
            Payment
          </Typography>
          <Divider sx={{ mb: "1rem" }} />
          <CheckoutPayment />
        </Box>

        <Box sx={{ padding: "1rem", display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: "1rem",
              gap: 2,
            }}
          >
            <ButtonComponent
              className="responsive_fontsize22"
              variant="contained"
              style={{ textTransform: "none" }}
              size="large"
              sx={{ background: "gray" }}
              onClick={() => navigate(`/menu`)}
            >
              Go Back
            </ButtonComponent>
            <ButtonComponent
              className="responsive_fontsize22"
              onClick={handleConfirmOrder}
              variant="contained"
              style={{ textTransform: "none" }}
              size="large"
            >
              Confirm Order
            </ButtonComponent>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={openConfirmationDialog}
        fullScreen={fullScreen}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="responsive-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            width: "80%",
            height: "auto",
            padding: "1rem",
          },
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          className="responsive_fontsize24"
          fontWeight="bold"
          textAlign="center"
        >
          Confirm your Order
        </DialogTitle>
        <DialogContent>
          <ConfirmationAlert
            orderedList={orderedList}
            totalAmount={parseFloat(calculateTotalPrice()).toFixed(2)}
            deliveryCharge={deliveryCharge}
            open={openConfirmationDialog}
            onClose={handleCloseConfirmationDialog}
            totalPrice={totalAmount()}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CheckoutSection;
