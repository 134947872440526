  import React from "react";
  import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Divider,
    IconButton,   
    Grid,
    Box,
  } from "@mui/material";
  import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
  import { useDispatch } from "react-redux";
  import {
    removeItem,
    updateQuantity,
    incrementQuantity,
    decrementQuantity,
  } from "../../redux/slices/cartSlice";
  import PropTypes from "prop-types";
  import IncrementDecrement from "../ui/increment_decrement";

  function AlignOrderList({ orderedFood }) {
    const dispatch = useDispatch();
  // Handle remove item action
  const handleRemoveItem = (id) => {
    dispatch(removeItem({ id, subcategory: orderedFood.subcategory }));
  };

    const handleQuantityChange = (newQuantity) => {
      dispatch(updateQuantity({ id: orderedFood.id, subcategory: orderedFood.subcategory, quantity: newQuantity }));
    };

    // Handle increment action
    const handleIncrement = () => {
      dispatch(incrementQuantity({ id: orderedFood.id, subcategory: orderedFood.subcategory }));
    };

    // Handle decrement action
    const handleDecrement = () => {
      dispatch(decrementQuantity({ id: orderedFood.id, subcategory: orderedFood.subcategory }));
    };


    return (
      <>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={8}>
            <List sx={{ width: "100%", padding: "0.5rem 1rem" }}>
              <ListItem >
                <ListItemAvatar
                  sx={{
                    alignItems: "flex-start",
                    mr: { xs: "0.5rem", md: "1rem" },
                  }}
                >
                  <Avatar
                    src={orderedFood.image}
                    sx={{
                      height: { xs: "40px", md: "60px" },
                      width: { xs: "40px", md: "60px" },
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <Typography>
                      <Typography
                        className="responsive_fontsize16"
                        sx={{
                          // Adjust font size for different breakpoints
                          fontWeight: "600",
                          marginRight: "0.5rem",
                        }}
                        component="span"
                        color="secondary.main"
                      >
                        {orderedFood.subcategory}
                      </Typography>
                      <IconButton
                        onClick={() => handleRemoveItem(orderedFood.id || orderedFood.subcategory)}
                        sx={{ padding: "0rem 0.2rem" }}
                      >
                        <DeleteOutlineTwoToneIcon
                          className="responsive_fontsize22"
                          sx={{
                            color: "black",
                            height: { xs: "20px", md: "24px" },
                            width: { xs: "20px", md: "24px" },
                          }}
                        />
                      </IconButton>
                    </Typography>
                  }
                  secondary={
                    <Typography>
                      <Typography
                        className="responsive_fontsize12"
                        sx={{ display: "inline" }}
                        component="span"
                        fontWeight="400"
                        color="secondary.main"
                      >
                        {orderedFood.detail}
                      </Typography>
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid
            item
            xs={8}
            md={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              className="responsive_fontsize12"
              sx={{ display: "inline" }}
              component="span"
              fontWeight="600"
              color="secondary.main"
            >
              {orderedFood.price[0]}
              {orderedFood.price[1]}
            </Typography>
            <IncrementDecrement
              id={orderedFood.id}
              subcategory={orderedFood.subcategory}
              count={orderedFood.quantity}
              setCount={(quantity) => handleQuantityChange(orderedFood.id, orderedFood.subcategory, quantity)}
              onIncrement={handleIncrement}
              onDecrement={handleDecrement}
            />
          </Grid>
        </Grid>
        <Box sx={{ margin: "0.5rem 1rem 0 1rem" }}>
          <Divider />
        </Box>
      </>
    );
  }

  AlignOrderList.propTypes = {
    orderedFood: PropTypes.object.isRequired,
  };

  export default AlignOrderList;
