import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Box, Typography } from "@mui/material";
import FoodCard from "../FoodCard/FoodCard";
import {
  decrementMenuItemQuantity,
  incrementMenuItemQuantity,
  removeItemFromMenu
} from "src/redux/slices/menuSlice";


const MenuItemList = ({ menuName }) => {
  const dispatch = useDispatch();

  // Select the menu from the Redux store based on the provided menu name.
  const menu = useSelector((state) =>
    state.menus.selectedMenus.find((m) => m.menuName === menuName)
  );

  if (!menuName) {
    console.error("Menu name is undefined.");
    return <Typography>Menu name is not defined.</Typography>;
  }

  if (!menu) {
    console.error(`Menu with name "${menuName}" not found.`);
    return <Typography>No menu found with name "{menuName}".</Typography>;
  } else {
    console.log("There is a menu available with items", menu.selectedItems);
  }

  // Handle deleting an item from the menu.
  const handleDeleteClick = (itemId) => {
    dispatch(removeItemFromMenu({ menuName, itemId }));
  };

  // Handle incrementing the quantity of a menu item.
  const handleIncrement = (itemId) => {
    dispatch(incrementMenuItemQuantity({ menuName, itemId }));
  };

  // Handle decrementing the quantity of a menu item.
  const handleDecrement = (itemId) => {
    dispatch(decrementMenuItemQuantity({ menuName, itemId }));
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        {menu.selectedItems.length > 0 ? (
          menu.selectedItems.map((item) =>
            item ? (
              <Grid item key={item.id}>
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* FoodCard Component to display the food item */}
                  <FoodCard
                    fooditem={item}
                    subcategory={item.subcategory}
                    quantity={item.quantity}
                    handleDeleteCard={() => handleDeleteClick(item.id)}
                    handleIncrement={() => handleIncrement(item.id)}
                    handleDecrement={() => handleDecrement(item.id)}
                    
                  />
                </Box>
              </Grid>
            ) : null
          )
        ) : (
          <Typography>No items available.</Typography>
        )}
      </Grid>
      <Typography
        variant="h6"
        className="responsive_fontsize14"
        sx={{ textAlign: "center", fontFamily: "roboto serif" }}
      >
        Total No of Food Items In Menu: {menu.selectedItems.length || 0}
      </Typography>
    </>
  );
};

export default MenuItemList;
