import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CancelIcon from "@mui/icons-material/Cancel";
import { Typography, Box, IconButton, Divider } from "@mui/material";
import { format } from "date-fns";

import notificationListData from "./notification_list"; // Ensure this is the correct path to your data file

// Utility function to truncate text to a certain number of words
const truncateText = (text, maxLength) => {
  if (text.split(" ").length > maxLength) {
    return text.split(" ").slice(0, maxLength).join(" ") + "...";
  }
  return text;
};

function NotificationList({ onClose }) {
  return (
    <Box
    className="custom-scrollbar"
      sx={{
        overflowY: "auto",
        bgcolor: "primary.main",
        height: "90vh",
      }}
    >
      <IconButton onClick={onClose} sx={{ml: 1}}>
        <CancelIcon />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexDirection:"column",
          justifyContent: "space-between",
          alignItems: "center",
          ml: 2,
            overflow:"hidden"
        }}
      >
        {/* notification text */}
        <Box
          sx={{
            width: "100%",
            p: 1,
            ml: 2,
            background: "linear-gradient(90deg, #4CAF50, #FFC107)",
            borderRadius: "8px 0px  0 8px",
            padding: "0.5rem 1rem",
            mb:'0.8rem'
          }}
        >
          <Typography
            variant="h5"
            sx={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "flex-start",
            overflow:"hidden"
              
            }}
          >
            Notification
          </Typography>
        </Box>

        {/* all / unread */}
        <Box
          sx={{
            display:'flex',
            gap:"1rem",
            width: "100%",
            p: 1,
            ml: 2,
            background: "#483C43",
            borderRadius: "8px 0px  0 8px",
            padding: "0.5rem 1rem",
            // mb:'1.5rem',
            overflow:"hidden"
          }}
        >
          <Typography className="responsive_fontsize20">
            All 
          </Typography>
          <Typography className="responsive_fontsize20">
            Unread
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      ></Box>

      <Box sx={{ml: 2, mr: 1.5}}>
      {/* today */}
      <Box
        className="custom-scrollbar"
        sx={{ 
          // maxHeight: 320,
          maxHeight: '80%',
           overflowY: "auto", padding:"1rem", }}
      >
        <Typography variant="h5"
            sx={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              top:'0',
              // position:"static"
              
            }}>
          Today
        </Typography>
        <List
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            bgcolor: "primary.main",
          }}
        >
          {notificationListData.map((item) => {
            const formattedDate = format(
              new Date(item.notificationDate),
              "MM/dd/yyyy"
            );
            const formattedTime = format(
              new Date(item.notificationDate),
              "hh:mm:ss a"
            );

            return (
              <React.Fragment key={item.id}>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    my: 1,
                    backgroundColor: "white",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    borderRadius: "0.5rem",
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={item.notificationText}
                      src={item.notificationImage}
                      sx={{
                        width: 56,
                        height: 56,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        marginRight: "0.8rem",
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography className="responsive_fontsize14">
                        {truncateText(item.notificationText, 8)}
                      </Typography>
                    }
                    secondary={
                      <Typography
                      className="responsive_fontsize14"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: "0.5rem",
                        }}
                        component="span"
                        color="text.secondary"
                      >
                        <span>{formattedDate}</span>
                        <span>{formattedTime}</span>
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="middle" />
              </React.Fragment>
            );
          })}
        </List>
      </Box>
      </Box>
    </Box>
  );
}

export default NotificationList;
