import React, { useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Dataset from "src/components/offerSection/dataItems";
import SearchBar from "src/components/search_bar/search_bar_main";
import SocialMediaBar from "src/components/social_media_bar/social_media_bar_main";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ItemList from "./ItemListContainer";
import PaginationControls from "./PaginationControl";

function VegItems() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const itemsPerPage = 3;
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplaySpeed: 1000,
    autoplay: true,
    slidesToScroll: 1,
    arrows: false,
  };

  const filteredVegItems = Dataset.filter(
    (item) =>
      item.classification.some((classif) => classif.classified === "veg") &&
      item.subcategory.some(
        (sub) =>
          sub.toLowerCase().includes("veg") ||
          sub.toLowerCase().includes("cheese") ||
          sub.includes("Chilli")
      )
  );

  const flattenedItemsToShow = [];
  filteredVegItems.forEach((item) => {
    item.subcategory
      .filter(
        (sub) =>
          sub.toLowerCase().includes("veg") ||
          sub.toLowerCase().includes("cheese") ||
          sub.includes("Chilli")
      )
      .forEach((sub) => {
        flattenedItemsToShow.push({ ...item, subcategory: sub });
      });
  });

  const [displayedItems, setDisplayedItems] = useState(() => {
    return flattenedItemsToShow.slice(0, itemsPerPage);
  });

  const handleMoreClick = () => {
    const currentPage = Math.ceil(displayedItems.length / itemsPerPage) + 1;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const newEndIndex = startIndex + itemsPerPage;
    const newItems = flattenedItemsToShow.slice(startIndex, newEndIndex);
    const uniqueNewItems = newItems.filter(
      (item) => !displayedItems.some((d) => d.id === item.id)
    );
    setDisplayedItems([...displayedItems, ...uniqueNewItems]);
  };

  const handleLessClick = () => {
    const currentPage = Math.ceil(displayedItems.length / itemsPerPage) - 1;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const newItems = flattenedItemsToShow.slice(
      startIndex,
      startIndex + itemsPerPage
    );
    setDisplayedItems(newItems);
  };

  console.log("isMobile:", isMobile);
  console.log("displayedItems:", displayedItems);

  return (
    <>
      <SearchBar />
      <SocialMediaBar />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: 2,
          m: {
            xs: "7rem 20px",
            sm: "7rem 25px",
            md: "7rem 30px",
            lg: "7rem 35px",
          },
          px:{xs:2,sm:2,md:5,lg:10},
        }}
      >
        <Typography
          className="responsive_fontsize24"
          sx={{
            fontWeight: 700,
            fontFamily: "roboto serif",
            textAlign: "center",
            textTransform: "uppercase",
          }}
        >
          Veg Items
        </Typography>
        <ItemList
          displayedItems={displayedItems}
          isMobile={isMobile}
          sliderSettings={sliderSettings}
        />
        {!isMobile && (
          <PaginationControls
            flattenedItemsToShow={flattenedItemsToShow}
            displayedItems={displayedItems}
            itemsPerPage={itemsPerPage}
            handleMoreClick={handleMoreClick}
            handleLessClick={handleLessClick}
          />
        )}
      </Box>
    </>
  );
}

export default VegItems;
