import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  incrementMenuItemQuantity,
  decrementMenuItemQuantity,
  removeItemFromMenu,
} from "../../../../redux/slices/menuSlice";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";


const NumberInput = ({ menuName, itemId, quantity, onIncrement, onDecrement }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(quantity);

  useEffect(() => {
    setInputValue(quantity);
  }, [quantity]);

  const handleIncrement = () => {
    dispatch(incrementMenuItemQuantity({ menuName, itemId }));
  };

  const handleDecrement = () => {
    if (inputValue > 1) {
      dispatch(decrementMenuItemQuantity({ menuName, itemId }));
    } else if (inputValue === 1) {
      dispatch(removeItemFromMenu({ menuName, itemId }));
    }
  };

  const handleInputChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= 0) {
      setInputValue(newValue);
      if (newValue === 0) {
        dispatch(removeItemFromMenu({ menuName, itemId }));
      } else {
        // You can dispatch an update quantity action here if needed
      }
    }
  };

  return (
    <StyledInputRoot>
      <StyledButton onClick={handleDecrement} disabled={inputValue <= 0}>
        <RemoveIcon className="responsive_fontsize20" />
      </StyledButton>
      <StyledInput
        value={inputValue}
        onChange={handleInputChange}
        min={0}
        max={1000}
      />
      <StyledButton onClick={handleIncrement} disabled={inputValue <= 0}>
        <AddIcon className="responsive_fontsize20" />
      </StyledButton>
    </StyledInputRoot>
  );
};

// Styled components for consistent UI
const StyledInputRoot = styled("div")`
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 300;
  display: flex;
  align-items: center;
`;

const StyledInput = styled("input")`
  font-size: 0.65rem;
  font-family: inherit;
  font-weight: 300;
  line-height: 0.25;
  padding: 1px 1px;
  background: none;
  width: 2rem;
  border: 1px solid black;
  text-align: center;
`;

const StyledButton = styled("button")`
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 0.125rem;
  line-height: 0.125;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: 0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default NumberInput;
