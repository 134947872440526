import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  Container,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import backgroundImage from "../../../..//assets/logo.jpg";
import axios from "axios";
import ForgotPasswordDialog from "./forgotPasswordDialog";
import { loginSuccess } from "../../../../redux/actions/actions";
import { toast } from 'react-toastify';

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleForgotPasswordClick = () => {
    setOpenDialog(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("http://localhost:8001/bbgapi/users/login", {
        customer_contact: formData.username,
        customer_password: formData.password,
      });

      const { data } = response.data;
      if (data && data.customer) {
        const { customer, token } = data;
        localStorage.setItem('authToken', token);
        localStorage.setItem('customerId', customer.cid);
        dispatch(loginSuccess());
        toast.success(`Login successful! Welcome to BBG ${customer.customer_name} `);
        navigate("/profile");
      } else {
        console.error("Customer data is missing in the response:", data);
        setError(true);
      }
    } catch (error) {
      if (error.response) {
        console.error("Login error:", error.response.data);
        setError(true);
      } else {
        console.error("Login error:", error.message);
        setError(true);
      }
    }
  };

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Box
        sx={{
          marginTop: "10rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid container>
          <Grid
            item
            sm={"none"}
            md={5}
            sx={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "65vh",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              ml: { sm: "5rem", md: "11.8rem" },
              height: { xs: "60vh", sm: "68vh" },
            }}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                className="responsive_fontsize48"
                color="green"
              >
                Sign In
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: { xs: 2.7, sm: 3.5 } }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  name="username"
                  label="Contact Number or Email"
                  autoComplete="username"
                  autoFocus
                  value={formData.username}
                  onChange={handleInputChange}
                  error={error}
                  helperText={error ? "Incorrect email" : ""}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  value={formData.password}
                  onChange={handleInputChange}
                  error={error}
                  helperText={error ? "Incorrect password" : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          onClick={handleClickShowPassword}
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className="responsive_fontsize18"
                  sx={{ mt: 1, mb: 3.2 }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link
                      href="#"
                      variant="body2"
                      color="#006400"
                      className="responsive_fontsize18"
                      onClick={handleForgotPasswordClick}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      href="#"
                      variant="body2"
                      color="primary"
                      className="responsive_fontsize20"
                      onClick={handleSignUpClick}
                    >
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Forgot Password Dialog */}
      <ForgotPasswordDialog open={openDialog} onClose={() => setOpenDialog(false)} />
    </Container>
  );
};

export default LoginForm;
