import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Popover,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  openConfirmDeleteDialog,
  setCurrentMenu,
  addItemToMenu
} from "src/redux/slices/menuSlice";

function FavoritePopover({
  open,
  onClose,
  anchorEl,
  onAddMenuName,
}) {
  const dispatch = useDispatch();
  const { selectedMenus, foodItemToAdd } = useSelector((state) => state.menus);

  const handleMenuClick = (menuName) => {
    dispatch(setCurrentMenu(menuName));
    dispatch(addItemToMenu({ menuName, item: foodItemToAdd }));
    onClose();
  };
  return (
    <Popover
      anchorEl={anchorEl}
      id="favorite-popover"
      open={open}
      onClose={onClose}
      disableRestoreFocus
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex" }}>
          <IconButton onClick={onAddMenuName}>
            <AddIcon />
          </IconButton>
          <Typography sx={{ p: 2 }}>Make Your Menu!</Typography>
        </Box>
        <List>
          {selectedMenus.map((menu, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleMenuClick(menu.menuName)}
            >
              <ListItemText primary={menu.menuName} />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() =>
                    dispatch(openConfirmDeleteDialog(menu.menuName))
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box>
    </Popover>
  );
}

FavoritePopover.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  foodItem: PropTypes.object,
};

export default FavoritePopover;
