import React from "react";
import { Helmet } from "react-helmet-async";
import HomeView from "../views/home";

function HomePage() {
  
  return (
    <>
      <Helmet>
        <title>Home | BBG</title>
        <meta
          name="description"
          content="Welcome to our restaurant. Explore our menu and order online"
        />
      </Helmet>
      <HomeView  />
      
    </>
  );
}

export default HomePage;