// data.js
const initialData = [
  {
    username: 'Ayusha',
    password: '1234',
    contactNumber: '9768246535',
    email: 'asuyapaudel@gmail.com',
  },
  {
    username: 'Ashika',
    password: '5678',
    contactNumber: '9849446617',
    email: 'ashikapaudel93@gmail.com',
  },
  {
    username: 'Nabina',
    password: 'nabeena@123',
    contactNumber: '986882347',
    email: 'nabeenasinjalee@gmail.com',
  },
  {
    username: 'Samima',
    password: 'sarif786',
    contactNumber: '9812345678',
    email: 'samimamiya786@gmail.com',
  },
  {
    username: 'Sima',
    password: '@poonam#21$2004',
    contactNumber: '9704831889',
    email: 'simathapa3333@gmail.com',
  }
];

export default initialData;
