
import React from "react";
import { Box, Divider, Typography, IconButton, Grid } from "@mui/material";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import IncrementDecrement from "../ui/increment_decrement"; 
import { useDispatch } from "react-redux";
import { removeItem } from "../../redux/slices/cartSlice";

function OrderConfirmationItems({
  orderedList,
  handleQuantityChange,
  handleIncrement,
  handleDecrement,
  
}) {
  const dispatch = useDispatch();

  // Handle remove item action
  const handleRemove = (id, subcategory) => {
    dispatch(removeItem({ id, subcategory }));
  };

  // Calculate total price function based on orderedList items
  const calculateTotalPrice = () => {
    return orderedList
      .reduce((total, item) => {
        const price = parseFloat(item.price[1]); // Assuming item.price is an array and we want to use the second element
        return total + price * item.quantity;
      }, 0)
      .toFixed(2); // Ensure toFixed(2) for proper decimal formatting
  };

  // If no items are present, display a message
  if (!orderedList || orderedList.length === 0) {
    return (
      <Typography className="responsive_fontsize16">
        No items in the order.
      </Typography>
    );
  }

  // Render the list of orderedList items with controls for quantity and deletion
  return (
    <Box >
      {orderedList.map((item, index) => (
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          key={item.id}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "1rem",
          }}
        >
          <Grid
            item
            xs={4}
            md={4}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Typography className="responsive_fontsize16">
              {index + 1}.
            </Typography>
            <Typography className="responsive_fontsize16">
              {item.subcategory}
            </Typography>
            <IconButton
              onClick={() => handleRemove(item.id, item.subcategory)}
              sx={{ padding: "0.2rem" }}
            >
              <DeleteOutlineTwoToneIcon />
            </IconButton>
          </Grid>

          <Grid item xs={4} md={4}>
            <IncrementDecrement
              id={item.id}
              subcategory={item.subcategory}
              count={item.quantity}
              handleQuantityChange={handleQuantityChange}
              handleIncrement={handleIncrement}
              handleDecrement={handleDecrement}
            />
          </Grid>

          <Grid
            item
            xs={4}
            md={4}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Typography className="responsive_fontsize16">
              {item.price[0]} {parseFloat(item.price[1]).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      ))}
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Typography className="responsive_fontsize18" sx={{fontWeight:'bold'}}>Total Price</Typography>
        <Typography className="responsive_fontsize18" sx={{fontWeight:'bold'}}>
          Rs. {calculateTotalPrice()}
        </Typography>
      </Box>
    </Box>
  );
}

export default OrderConfirmationItems;
