import React, { useState, useEffect } from "react";
import { Button, Box, useMediaQuery } from "@mui/material";
import FoodCard from "./foodcard";
import foodItemsData from "./dataItems"; // Import the food items data

function FoodCardList() {
  const isExtraSmall = useMediaQuery("(max-width: 600px)");
  const isSmall = useMediaQuery("(max-width: 1080px)");
  const isMedium = useMediaQuery("(max-width: 1600px)");

  const [visibleCount, setVisibleCount] = useState(4);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (isExtraSmall) {
      setVisibleCount(1);
    } else if (isSmall) {
      setVisibleCount(2);
    } else if (isMedium) {
      setVisibleCount(3);
    } else {
      setVisibleCount(4);
    }
  }, [isExtraSmall, isSmall, isMedium]);

  const handleShowMore = () => {
    setVisibleCount(flattenedFoodItems.length);
    setShowMore(true);
  };

  const handleShowLess = () => {
    if (isExtraSmall) {
      setVisibleCount(1);
    } else if (isSmall) {
      setVisibleCount(2);
    } else if (isMedium) {
      setVisibleCount(3);
    } else {
      setVisibleCount(4);
    }
    setShowMore(false);
  };

  const flattenedFoodItems = foodItemsData.flatMap((foodItem) =>
    foodItem.subcategory.map((subcat, index) => ({
      ...foodItem,
      subcategory: subcat,
      subcategoryId: `${foodItem.id}-${index}`,
    }))
  );

  return (
    <Box sx={{ padding: "16px" }}>
      <Box
        sx={{
          padding: "20px 0 20px 0",
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: {
            xs: "1rem",
            sm: "2.8rem",
            md: "3rem",
            lg: "3.8rem",
            xl: "4.5rem",
          },
          justifyContent: "center",
          alignItems: "center",
          "@media (max-width: 1600px)": {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
          "@media (max-width: 1080px)": {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
          "@media (max-width: 600px)": {
            gridTemplateColumns: "repeat(1, 1fr)",
          },
        }}
      >
        {flattenedFoodItems.slice(0, visibleCount).map((foodItem) => (
          <FoodCard key={foodItem.subcategoryId} foodItem={foodItem} subcategory={foodItem.subcategory} />
        ))}
      </Box>
      <Box sx={{ textAlign: "center", marginTop: "16px" }}>
        {!showMore ? (
          <Button variant="contained" onClick={handleShowMore}>
            Show More
          </Button>
        ) : (
          <Button variant="contained" onClick={handleShowLess}>
            Show Less
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default FoodCardList;
