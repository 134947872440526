import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import foodItemsData from "../offerSection/dataItems";
import FoodCard from "src/views/menu/components/FoodCard/FoodCard";
import { Box } from "@mui/material";

function RelatedCardSlider() {
  // Flatten the foodItemsData to display subcategories individually
  const flattenedFoodItems = foodItemsData.flatMap((foodItem) =>
    foodItem.subcategory.map((subcat, index) => ({
      ...foodItem,
      subcategory: subcat,
      subcategoryId: `${foodItem.id}-${index}`,
    }))
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      className="center__carousel"
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <Slider {...settings}>
        {flattenedFoodItems.map((foodItem) => (
          <FoodCard
            key={foodItem.subcategoryId}
            fooditem={foodItem}
            subcategory={foodItem.subcategory}
            // quantity={0}
            handleDeleteCard={() => {}}
            handleIncrement={() => {}}
            handleDecrement={() => {}}
          />
        ))}
      </Slider>
    </Box>
  );
}

export default RelatedCardSlider;