import React from "react";
import {
  Box,
  Typography,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';


function FoodCardContent({ fooditem, subcategory ,handleAddToCart}) {
  const navigate = useNavigate();

  const handleDetailsClick = () => {
    navigate('/description', { state: { foodItem: fooditem, subcategory } });
  };
  
  return (
    <CardContent>
      <CardMedia
        component="img"
        image={fooditem.image}
        style={{
          width: "15rem", 
          height: "10rem",
          borderRadius: "50%",
          margin: "1.5rem auto", 
          padding:"1rem"
        }}
      />
      <Typography
        className="responsive_fontsize18"
        variant="subtitle1"
        sx={{ my: 0.3, textAlign: "center" }}
      >
        {subcategory}
      </Typography>
      <Box>
        <span
          style={{
            padding: "8px",
            position: "absolute",
            right: "0",
            top: "0",
            backgroundColor: "white",
            borderRadius: "4px",
          }}
        >
          <Typography
            className="responsive_fontsize14"
            sx={{ textAlign: "center", color: "#A76C00" }}
          >
            {fooditem.price}
          </Typography>
        </span>
        <Box
          sx={{
            display: "flex",
            gap: "0.5rem",
            justifyContent: "center",
            padding: "0 1rem"
          }}
        >
          <Button
            className="responsive_fontsize16"
            variant="contained"
            color="secondary"
            fullWidth
            sx={{
              mt: 1,
              textTransform: "capitalize",
              borderRadius: "0.375rem",
              color: "white",
              height: "2rem",
            }}
            onClick={handleDetailsClick}
          >
            Details
          </Button>
          <Button
            className="responsive_fontsize16"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAddToCart}
            sx={{
              mt: 1,
              textTransform: "capitalize",
              borderRadius: "0.375rem",
              color: "white",
              height: "2rem",
            }}
          >
            Add To Cart
          </Button>
        </Box>
      </Box>
    </CardContent>
  );
}

FoodCardContent.propTypes = {
  fooditem: PropTypes.shape({
    image: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
  }).isRequired,
  subcategory: PropTypes.string.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
};

export default FoodCardContent;
