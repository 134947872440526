// cartSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],           // Main cart items
  alignOrderList: [],
  checkoutItems: [], 
  orderedItems: {},    // Ordered items (using an object for mapping)
  orderConfirmed: false // Flag to indicate if an order has been confirmed
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const newItem = action.payload; // Assuming newItem is correctly passed as an object with a 'name' property
      const existingItem = state.items.find(
        (item) => item.id === newItem.id && item.subcategory === newItem.subcategory
      );

      if (existingItem) {
        existingItem.quantity++;
      } else {
        state.items.push({ ...newItem, quantity: 1 });
      }
    },
    moveCheckoutToCart: (state) => {
      state.items.push(...state.checkoutItems);
      state.checkoutItems = [];
    },
    removeItem: (state, action) => {
      const { id, subcategory } = action.payload;
      state.items = state.items.filter(
        (item) => !(item.id === id && item.subcategory === subcategory)
      );
    },
    updateQuantity: (state, action) => {
      const { id, subcategory, quantity } = action.payload;
      const itemToUpdate = state.items.find(
        (item) => item.id === id && item.subcategory === subcategory
      );
      if (itemToUpdate) {
        itemToUpdate.quantity = quantity;
      }
    },
    incrementQuantity: (state, action) => {
      const { id, subcategory } = action.payload;
      const itemToIncrement = state.items.find(
        (item) => item.id === id && item.subcategory === subcategory
      );
      if (itemToIncrement) {
        itemToIncrement.quantity++;
      }
    },
    decrementQuantity: (state, action) => {
      const { id, subcategory } = action.payload;
      const itemToDecrement = state.items.find(
        (item) => item.id === id && item.subcategory === subcategory
      );
      if (itemToDecrement && itemToDecrement.quantity > 1) {
        itemToDecrement.quantity--;
      } else if (itemToDecrement && itemToDecrement.quantity === 1) {
        state.items = state.items.filter(
          (item) => !(item.id === id && item.subcategory === subcategory)
        );
      }
    },
    clearCart: (state) => {
      return {
        ...state,
        items: [],         // Reset main cart items to empty array
        orderedItems: {},  // Reset ordered items to empty object
      };
    },
    confirmOrder: (state) => {
      state.orderConfirmed = true;
      state.items = [];         // Reset main cart items to empty array after order confirmation
      state.orderedItems = {};  // Reset ordered items to empty object after order confirmation
    },
    clearOrderConfirmed: (state) => {
      state.orderConfirmed = false;
    },
    addToOrderedItems: (state, action) => {
      const newItem = action.payload;
      state.orderedItems[newItem.id] = newItem; // Assuming orderedItems is an object mapping
    },
    addToOrderList: (state, action) => {
      state.alignOrderList = action.payload;
    },
    addToCheckout: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.find(
        (item) => item.id === newItem.id && item.subcategory === newItem.subcategory
      );

      if (existingItem) {
        existingItem.quantity++;
      } else {
        state.items.push({ ...newItem, quantity: 1 });
      }
    },
  },
});

export const {
  addItem,
  removeItem,
  updateQuantity,
  incrementQuantity,
  decrementQuantity,
  clearCart,
  confirmOrder,
  clearOrderConfirmed,
  addToOrderedItems,
  addToOrderList,
  addToCheckout,
  moveCheckoutToCart
} = cartSlice.actions;

export default cartSlice.reducer;
