import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";

// Haversine formula for calculating distance between two coordinates
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    0.5 -
    Math.cos(dLat) / 2 +
    (Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      (1 - Math.cos(dLon))) /
      2;
  return R * 2 * Math.asin(Math.sqrt(a));
};

function OrderNotificationSystem() {
  const [currentUserLocation, setCurrentUserLocation] = useState(null);
  const [orderLocation, setOrderLocation] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    // Get the current user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          console.error("Error fetching location.");
        }
      );
    }

    // Simulate order location - in real case, you'd get this from the server
    setOrderLocation({
      lat: 27.681245, // Latitude of Ekantakuna, Lalitpur
      lng: 85.318791, // Longitude of Ekantakuna, Lalitpur
    });
  }, []);

  useEffect(() => {
    if (currentUserLocation && orderLocation) {
      // Check the distance between the current user and the placed order
      const distance = calculateDistance(
        currentUserLocation.lat,
        currentUserLocation.lng,
        orderLocation.lat,
        orderLocation.lng
      );

      // If distance is less than or equal to 1km, show the dialog
      if (distance <= 1) {
        setDialogOpen(true);
      }
    }
  }, [currentUserLocation, orderLocation]);

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      {currentUserLocation && (
        <Typography variant="body1">
          Your location: Lat {currentUserLocation.lat}, Lng{" "}
          {currentUserLocation.lng}
        </Typography>
      )}

      {/* Alert Dialog */}
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <DialogTitle>Nearby Order Placed!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A customer has just placed an order around you. Would you like to
            make an order as well?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No, Thanks</Button>
          <Button onClick={handleClose} variant="contained" color="primary">
            Yes, Place Order
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default OrderNotificationSystem;
