import React from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from 'prop-types';
import { openConfirmDeleteDialog } from "src/redux/slices/menuSlice";
import { useDispatch} from "react-redux";


function MenuName({ selectedMenus, handleMenuSelection, handleDeleteMenu }) {
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: { xs: "50%", sm: "20%", md: "200px" },
        padding: 2,
        marginTop: 2,
        borderRadius: 4,
        textAlign: "center",
        height: "auto",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
      }}
    >
      <Typography
        variant="h6"
        className="responsive_fontsize20"
        sx={{ textAlign: "center", marginBottom: 2, fontWeight: 'bold' }}
      >
        Menu Names
      </Typography>
      <Box
        className="responsive_fontsize14"
        sx={{ overflowY: "auto", maxHeight: 200, padding: 1 }}
      >
        {selectedMenus.map((menu, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: 1,
              backgroundColor: "#f9f9f9",
              borderRadius: 2,
              padding: 1,
              '&:hover': {
                backgroundColor: "#ececec",
              },
            }}
          >
            <Button
              onClick={() => handleMenuSelection(menu.menuName)}
              sx={{
                width: "100%",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
                textAlign: "left",
                padding: "0 8px",
                '&:hover': {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Typography variant="body2" sx={{ flexGrow: 1 }}>
                {menu.menuName}
              </Typography>
            </Button>
            <IconButton onClick={() => dispatch(openConfirmDeleteDialog(menu.menuName))}>
              <DeleteIcon
                className="responsive_fontsize20"
                sx={{ color: "red" }}
              />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

MenuName.propTypes = {
  selectedMenus: PropTypes.arrayOf(
    PropTypes.shape({
      menuName: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleMenuSelect: PropTypes.func.isRequired,
  handleDeleteMenu: PropTypes.func.isRequired,
};

export default MenuName;
