import React from 'react';
import { Grid, Box } from '@mui/material';
import FoodCard from '../FoodCard/FoodCard';

function GridLayout({ searchedItems }) {
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      {searchedItems.map(
        (fooditem) =>
          fooditem.subcategory &&
          fooditem.subcategory.map((sub, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={`${fooditem.id}-${sub}-${index}`}
            >
              <Box sx={{ position: "relative"}} >
                <FoodCard fooditem={fooditem} subcategory={sub} />
              </Box>
            </Grid>
          ))
      )}
    </Grid>
  );
}

export default GridLayout;