import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import FoodCard from "src/views/menu/components/FoodCard/FoodCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ItemList = ({ displayedItems, isMobile, sliderSettings }) => (
  <>
    {isMobile ? (
      <Box
        sx={{
          width: "100%",
          "& .slick-slide": {
            display: "flex",
            justifyContent: "center",
          },
        }}
      >
        <Slider {...sliderSettings}>
          {displayedItems.map((item, index) => (
            <FoodCard
              key={`${item.id}-${index}`}
              fooditem={item}
              subcategory={item.subcategory}
            />
          ))}
        </Slider>
      </Box>
    ) : (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <Grid container justifyContent="center" spacing={2}>
          {displayedItems.length > 0 ? (
            displayedItems.map((item, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={`${item.id}-${index}`}
              >
                <FoodCard
                  key={`${item.id}-${index}`}
                  fooditem={item}
                  subcategory={item.subcategory}
                />
              </Grid>
            ))
          ) : (
            <Typography variant="subtitle1" color="textSecondary">
              No veg items available.
            </Typography>
          )}
        </Grid>
      </Box>
    )}
  </>
);

export default ItemList;
