import React from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import Dataset from "../../../../components/offerSection/dataItems";
import FoodCard from "../FoodCard/FoodCard";
import {
  setAnchorElId,
  openPopover as openPopoverAction,
  closePopover,
  openMenuNameDialog as MenuDialog,
  closeMenuNameDialog,
} from "src/redux/slices/menuSlice";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";

const FoodItemsGrid = ({
  selectedCategory,
  selectedMenus,
  handleMenuUpdate,
  subcategory
}) => {
  const dispatch = useDispatch();
  const anchorElRef = useRef(null);
  const {
    menuName,
    confirmDeleteDialog,
    anchorElId,
  } = useSelector((state) => state.menus);

  const filteredItems = Dataset.filter(
    (item) => item.category === selectedCategory
  );

  const handleOpenPopover = (event, fooditem) => {
    if (event && event.currentTarget && fooditem) {
      console.log("Dispatching food item:", fooditem);
      const newAnchorElId = event.currentTarget.id;
      dispatch(setAnchorElId(newAnchorElId));
      const itemToAdd = {
        ...fooditem,
        subcategory: fooditem.subcategory,
      };
      // Dispatch the action to open the popover
      dispatch(openPopoverAction({
        anchorElId: newAnchorElId,
        popoverID: "foodcard",
        foodItem: { ...itemToAdd, quantity: 1 , subcategory: itemToAdd.subcategory}
      }));
    } else {
      console.error("Event or event.currentTarget or fooditem is undefined");
    }
  };

  const handleClosePopover = () => {
    dispatch(closePopover(anchorElId));
    anchorElRef.current = null;
  };

  const handleOpenMenuNameDialog = () => {
    dispatch(closePopover(anchorElId));
    anchorElRef.current = null;
    dispatch(MenuDialog());
  };

  const handleCloseMenuNameDialog = () => {
    dispatch(closeMenuNameDialog());
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      {filteredItems.length > 0 ? (
        filteredItems.map((fooditem) =>
          fooditem.subcategory?.map((sub, index) => (
            <Grid
              item
              key={`${fooditem.id}-${index}`}
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <Box
                sx={{
                  position: "relative",
                  ml: { xs: 0, md: 10 },
                  width: { xs: "100%", sm: "80%", md: "100%", lg: "95%" },
                }}
              >
                <FoodCard
                  fooditem={fooditem}
                  subcategory={sub}
                  selectedMenus={selectedMenus}
                  isSelected={fooditem.quantity > 0}
                  quantity={fooditem.quantity}
                  handleMenuUpdate={handleMenuUpdate}
                  handleOpenPopover={handleOpenPopover}
                  handleClosePopover={handleClosePopover}
                  handleOpenMenuNameDialog={handleOpenMenuNameDialog}
                  handleCloseMenuNameDialog={handleCloseMenuNameDialog}
                  menuName={menuName}
                  confirmDeleteDialog={confirmDeleteDialog}
                />
              </Box>
            </Grid>
          ))
        )
      ) : (
        <Box>No items available in this category.</Box>
      )}
    </Grid>
  );
};

FoodItemsGrid.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  selectedMenus: PropTypes.arrayOf(
    PropTypes.shape({
      menuName: PropTypes.string.isRequired,
      selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    })
  ).isRequired,
  currentMenu: PropTypes.object,
  handleMenuUpdate: PropTypes.func.isRequired,
  handleAddToMenu: PropTypes.func.isRequired,
};

export default FoodItemsGrid;
