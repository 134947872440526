// src/layout/map_api/utils/mapUtils.js

export const geocodeLocation = (location, setLocationText, map, setMarker) => {
    if (window.google && window.google.maps) {
        const geocoder = new window.google.maps.Geocoder(); // Instantiate Geocoder properly
        geocoder.geocode({ location }, (results, status) => {
            console.log('Geocode results:', results);
            console.log('Geocode status:', status);
            console.log('Map object:', map);

            if (status === "OK" && results[0]) {
                setLocationText(results[0].formatted_address);
                if (map) { // Check if map is not null
                    map.panTo(location); // Move map to the new location
                } else {
                    console.error("Map is not initialized.");
                }
                setMarker(new window.google.maps.Marker({ position: location, map })); // Set marker at new location
            } else {
                console.error("Geocode was not successful for the following reason: " + status);
            }
        });
    } else {
        console.error("Google Maps JavaScript API not loaded properly.");
    }
};

  
  export const calculateDistance = (lat1, lng1, lat2, lng2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLng = ((lng2 - lng1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in kilometers
  };
  