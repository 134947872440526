import React from "react";
import { Box, IconButton, Typography, Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import OrderedCardList from "./ordered_card_list";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function OrderListDrawer({ onClose }) {
  const orderedList = useSelector((state) => state.cart.items);
  const navigate = useNavigate();

  const handleCheckout = () => {
    onClose();
    setTimeout(() => {
      navigate("/checkout", { state: { orderedList } });
    }, 300);
  };

  const calculateTotalPrice = () => {
    return orderedList
      .reduce((total, item) => {
        const price = parseFloat(item.price[1]); // Convert to float if needed
        return total + price * item.quantity;
      }, 0)
      .toFixed(2); // Ensure proper decimal formatting
  };

  if (!orderedList || orderedList.length === 0) {
    return (
      <Box>
        No items in the cart.
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <IconButton onClick={onClose}>
          <CancelIcon />
        </IconButton>
      </Box>

      <Typography
        sx={{
          textAlign: 'center',
          fontSize: "28px",
          fontWeight: "700",
          mt: "0.5rem",
          mb: "1.5rem",
        }}
      >
        Order List
      </Typography>

      <OrderedCardList orderedList={orderedList} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "0rem 0.5rem",
        }}
      >
        {/* Delivery info */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            m: "1.5rem 3rem",
          }}
        >
          <Typography>Delivery Fee</Typography>
          <Typography>Free</Typography>
        </Box>

        {/* Price Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            m: "0rem 3rem",
          }}
        >
          <Typography fontSize="24px" fontWeight="bold">
            Total
          </Typography>
          <Typography>Rs. {calculateTotalPrice()}</Typography>
        </Box>

        {/* Place order button */}
        <Box sx={{ m: "0rem 3rem" }}>
          <Link to="/checkout" style={{ textDecoration: "none", width: "100%" }}>
            <Button
              onClick={handleCheckout}
              variant="contained"
              sx={{
                mt: "1rem",
                mb: "2rem",
                borderRadius: "0.5rem",
                color: "#fff",
                width: "100%",
                fontSize: "1.2rem",
                fontWeight: "600",
                fontFamily: "Roboto Serif",
                backgroundColor: "#000",
                "&:hover": {
                  backgroundColor: "#8e5a00",
                },
              }}
            >
              Place Order
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default OrderListDrawer;