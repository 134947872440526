import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  closePopover,
  setMenuName,
  closeMenuNameDialog,
  openConfirmDeleteDialog,
  closeConfirmDeleteDialog,
  addItemToMenu,
  addMenu,
} from "src/redux/slices/menuSlice";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoritePopover from "src/views/menu/components/make_menu/Popover_make_menu";
import FoodCardContent from "./FoodCardContent";
import FoodCardActions from "./FoodCardAction";
import MenuDialogs from "./MenuCardDialog";
import { addItem } from "src/redux/slices/cartSlice";


function FoodCard({
  fooditem,
  subcategory,
  quantity,
  handleDeleteCard,
  handleIncrement,
  handleDecrement,
  handleOpenPopover,
  handleClosePopover,
  handleOpenMenuNameDialog,
  handleCloseMenuNameDialog,
  menuName,
  confirmDeleteDialog,
  ond,
}) {
  const dispatch = useDispatch();
  const anchorElRef = useRef(null);
  const {
    openPopover,
    popoverID,
    foodItemToAdd,
    selectedFoodItem,
    selectedMenus,
    openMenuNameDialog,
    anchorElId,
  } = useSelector((state) => state.menus);

  const handleAddToCart = () => {
    const itemToAdd = {
      ...fooditem,
      subcategory: subcategory,
    };
    dispatch(addItem(itemToAdd));
    toast.success(`Added ${itemToAdd.subcategory} to your cart`);
  };

  const isMenuNameExists = (name) => {
    return selectedMenus.some((menu) => menu.menuName === name);
  };

  const handleSaveMenuName = () => {
    if (menuName.trim() === "") {
      alert("Menu name cannot be empty, please add new name");
    } else if (!isMenuNameExists(menuName)) {
      dispatch(addMenu({ menuName }));
      dispatch(closeMenuNameDialog());
      console.log("food item created", fooditem);
      dispatch(addItemToMenu({ menuName, item: foodItemToAdd }));
      dispatch(closePopover());
    } else {
      dispatch(
        addItemToMenu({menuName, item: foodItemToAdd})
      );
      console.log("food item added", fooditem);
      dispatch(closeMenuNameDialog());
      alert("Menu name already exists. Please choose a different name.");
    }
  };

  const handleInputChange = (e) => {
    dispatch(setMenuName(e.target.value));
  };

  const handleClose = () => {
    dispatch(closeMenuNameDialog());
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Card
        sx={{
          maxWidth: "300",
          width: { xs: "85%", sm: "90%", md: "90%", lg: "95%" },
          height: "auto",
          position: "relative",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          margin: { xs: "1rem auto", sm: "1rem auto", md: "2rem auto" },
          textAlign: "center",
          display: "flex",
          gap: 3,
          flexDirection: "column",
          alignItems: "center",
          border: quantity > 0 ? "2px solid green" : "none",
        }}
      >
        {quantity >= 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.3rem",
              justifyContent: "center",
            }}
          >
            <FoodCardActions
              quantity={quantity }
              handleIncrement={handleIncrement}
              handleDecrement={handleDecrement}
              handleDeleteCard={handleDeleteCard}
            />
          </Box>
        )}

        <IconButton
          sx={{
            position: "absolute",
            padding: "10px",
            top: 0,
            left: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={(event) => handleOpenPopover(event, fooditem)}
          id={`favorite-icon-${fooditem.id}`}
          ref={anchorElRef}
        >
          <FavoriteIcon
            className="responsive_fontsize18"
            sx={{
              color: "#FF0B0B",
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                scale: 0.2,
              },
            }}
          />
        </IconButton>

        <FoodCardContent
          fooditem={fooditem}
          subcategory={subcategory}
          quantity={quantity}
          handleAddToCart={handleAddToCart}
        />
      </Card>

      {openPopover &&
        popoverID === "foodcard" &&
        anchorElId === `favorite-icon-${fooditem.id}` && (
          <FavoritePopover
            open={openPopover}
            onClose={handleClosePopover}
            foodItemToAdd={foodItemToAdd}
            foodItem={selectedFoodItem}
            onAddMenuName={() => handleOpenMenuNameDialog(fooditem, anchorElId)}
            menuName={selectedMenus.map((menu) => menu.menuName)}
            anchorEl={document.getElementById(anchorElId)}
            handleMenuSelect={() => {}}
          />
        )}

      {openMenuNameDialog && (
        <MenuDialogs
          openMenuNameDialog={openMenuNameDialog}
          setOpenMenuNameDialog={handleCloseMenuNameDialog}
          menuName={menuName}
          setMenuName={(value) => dispatch(setMenuName(value))}
          confirmDeleteDialog={confirmDeleteDialog}
          setConfirmDeleteDialog={(state) =>
            state
              ? dispatch(openConfirmDeleteDialog())
              : dispatch(closeConfirmDeleteDialog())
          }
          deleteMenuName={menuName}
          confirmDelete={() => {}} // Provide appropriate function here
          handleInputChange={handleInputChange}
          handleClose={handleClose}
          handleSaveMenuName={handleSaveMenuName}
          fooditem={fooditem}
        />
      )}
    </Box>
  );
}

FoodCard.propTypes = {
  fooditem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
  }).isRequired,
  subcategory: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  handleDeleteCard: PropTypes.func.isRequired,
  handleIncrement: PropTypes.func.isRequired,
  handleDecrement: PropTypes.func.isRequired,
  handleMenuUpdate: PropTypes.func.isRequired,
  handleDeleteMenu: PropTypes.func.isRequired,
  handleOpenPopover: PropTypes.func.isRequired,
  handleClosePopover: PropTypes.func.isRequired,
  handleOpenMenuNameDialog: PropTypes.func.isRequired,
  handleCloseMenuNameDialog: PropTypes.func.isRequired,
  menuName: PropTypes.string.isRequired,
  confirmDeleteDialog: PropTypes.bool.isRequired,
};

export default FoodCard;