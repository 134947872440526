import React, { useState } from "react";
import { Popover, Typography, Box, Button, Grid } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const MenuPopoverComponent = ({ categories, selectedCategory, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          className="responsive_fontsize48"
          fontWeight="bold"
          marginBottom={3}
        >
          BBG SPECIAL
        </Typography>
        <Button
          onClick={handleClick}
          sx={{ marginLeft: "10px", cursor: "pointer" }}
        >
          <MenuIcon
            sx={{
              fontSize: "1.5rem",
              color: "#000000",
              marginRight: "3rem",
              marginBottom: "1rem",
            }}
          />{" "}
          {/* Adjusted fontSize here */}
        </Button>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Grid container direction="column" spacing={1}>
            {categories.map((category) => (
              <Grid item key={category}>
                <Button
                  onClick={() => {
                    onChange(category);
                    handleClose();
                  }}
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    fontWeight: "bold",
                    borderRadius: "20px",
                    backgroundColor:
                      selectedCategory === category ? "#000000" : "#ffffff",
                    color:
                      selectedCategory === category ? "#ffffff" : "#000000",
                    "&:hover": {
                      backgroundColor: "#000000",
                      color: "#ffffff",
                    },
                  }}
                >
                  {category}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
    </>
  );
};

export default MenuPopoverComponent;
