import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";


function DeleteMenuPopover({
  open,
  handleClose,
  deleteMenuName,
  handleDeleteMenu,
}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Menu</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the menu "{deleteMenuName}"?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleDeleteMenu} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteMenuPopover;
