import React from "react";
import { Box, Button, Grid } from "@mui/material";
import esewa from "./payment_assets/esewa_logo.png";
import khalti from "./payment_assets/khalti_logo.png";
import fonepay from "./payment_assets/fonepay_logo.png";
import mobileBanking from "./payment_assets/mob_banking.png";

function PaymentMethodSection() {
  return (
    <Box>
      <Grid
        sx={{
          display: "flex",
          gap: "0.8rem",
          justifyContent: "center",
          mt: "1rem",
          mb: "1rem",
        }}
        container
      >
        <Grid item xs={4} md={2}>
          {/* esewa */}
          <Button>
            <img
              src={esewa}
              alt="esewa logo payment selection"
              style={{
                height: "15px",
                width: "55px",
              }}
            />
          </Button>
        </Grid>

        {/* Khalti */}
        <Grid item xs={4} md={2}>
          <Button>
            <img
              src={khalti}
              alt="esewa logo payment selection"
              style={{
                height: "22px",
                width: "63px",
              }}
            />
          </Button>
        </Grid>

        {/* fonepay */}
        <Grid item xs={4} md={2}>
          <Button sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={fonepay}
              alt="esewa logo payment selection"
              style={{
                height: "20px",
                width: "80px",
              }}
            />
          </Button>
        </Grid>

        {/* mobile banking */}
        <Grid item xs={4} md={2}>
          <Button sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={mobileBanking}
              alt="esewa logo payment selection"
              style={{
                height: "13px",
                width: "88px",
              }}
            />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PaymentMethodSection;
