import React from "react";
import PropTypes from "prop-types";
import { Card, CardMedia } from "@mui/material";

function FoodCard({ foodItem }) {
  if (!foodItem) return null;

  const { image } = foodItem;

  return (
    <Card
      sx={{
        width: {
          xs: "340px",
          sm: "360px",
          md: "380px",
          lg: "380px",
          xl: "420px",
        },
        height: {
          xs: "300px",
          sm: "350px",
          md: "400px",
          lg: "420px",
          xl: "450px",
        },
        position: "relative",
        backgroundColor: "#261d1d",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardMedia
        component="img"
        image={image}
        alt="Product"
        sx={{ width: "100%", height: "100%", objectFit: "cover" }}
      />
    </Card>
  );
}

FoodCard.propTypes = {
  foodItem: PropTypes.shape({
    image: PropTypes.string.isRequired,
  }).isRequired,
};

export default FoodCard;