import React from "react";
import { Typography, Box, Grid, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import ReactPlayer from "react-player";
import SearchBar from "../../../components/search_bar/search_bar_main";
import SocialMediaBar from "../../../components/social_media_bar/social_media_bar_main";
import DiscountOfferSection from "./discountOfferSection";
import aboutData from "./aboutDataSet";

const CustomDivider = styled(Divider)(({ theme }) => ({
  width: "30%",
  margin: "auto",
  "&::before, &::after": {
    borderColor: "#EAA017",
    borderWidth: 2,
  },
  [theme.breakpoints.down("md")]: {
    width: "50%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));

function AboutDescription() {
  return (
    <>
      <SearchBar />
      <SocialMediaBar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "10px 0 30px 0",
        }}
      >
        <CustomDivider
          className="responsive_fontsize32"
          sx={{ fontWeight: "600",
           }}
        >
          About
        </CustomDivider>
      </Box>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ padding: "20px" }}>
            <Typography
              className="responsive_fontsize24"
              sx={{
                marginBottom: "10px",
                fontWeight: "400",
              }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum
              eligendi omnis harum temporibus quia corrupti sunt soluta iusto
              fugiat laudantium, a illum dolores nisi ipsum.
            </Typography>
            <Typography
              className="responsive_fontsize20"
              sx={{
                fontWeight: "300",
              }}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus
              explicabo repellat, corporis veritatis molestiae ipsam! In eaque
              facilis voluptas, aut rerum sequi necessitatibus reprehenderit a,
              error, repudiandae quae optio quas consequuntur officia.
              Necessitatibus nisi fuga nesciunt corrupti quasi neque
              repellendus!
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ReactPlayer
              style={{
                width: "100%",
                height: "auto",
                maxHeight: 300,
                maxWidth: "100%",
              }}
              url="https://www.youtube.com/watch?v=mTFYCX7bJ6w&pp=ygUsZm9vZCBjb29raW5nIGluIHJlc3RhdXJlbnQgYnkgY2hpZWYgaW5kaWE%3D"
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "15px" }}>
        <Typography
          className="responsive_fontsize28"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          Why to choose BBG?
        </Typography>
        <Box sx={{ flexGrow: 1 ,marginTop:"20px"}}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {aboutData.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                <DiscountOfferSection
                content={item}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default AboutDescription;