import React, { useState, useMemo } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Dataset from "src/components/offerSection/dataItems";
import SearchBar from "src/components/search_bar/search_bar_main";
import SocialMediaBar from "src/components/social_media_bar/social_media_bar_main";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ItemList from "./ItemListing";
import PaginationControls from "./PaginationControl";

function NonVegItems() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const itemsPerPage = 3; // Number of items to show per page
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplaySpeed: 2000,
    autoplay: true,
    slidesToScroll: 1,
    arrows: false,
  };

  const filteredItems = useMemo(
    () =>
      Dataset.reduce((acc, item) => {
        item.subcategory.forEach((sub) => {
          if (
            !sub.toLowerCase().includes("veg") &&
            !sub.toLowerCase().includes("cheese") &&
            !sub.includes("Luxury") &&
            !sub.includes("Chilli")
          ) {
            acc.push({
              ...item,
              subcategory: sub,
            });
          }
        });
        return acc;
      }, []),
    []
  );

  // State to manage items to display
  const [displayedItems, setDisplayedItems] = useState(() => {
    const initialEndIndex =
      itemsPerPage > filteredItems.length ? filteredItems.length : itemsPerPage;
    return filteredItems.slice(0, initialEndIndex);
  });

  // Handler for 'More' button click
  const handleMoreClick = () => {
    const currentEndIndex = displayedItems.length + itemsPerPage;
    const newItems = filteredItems.slice(0, currentEndIndex);
    setDisplayedItems(newItems);
  };

  // Handler for 'Show Less' button click
  const handleLessClick = () => {
    const currentEndIndex = displayedItems.length - itemsPerPage;
    const newItems = filteredItems.slice(
      0,
      currentEndIndex < 0 ? 0 : currentEndIndex
    );
    setDisplayedItems(newItems);
  };

  return (
    <>
      <SearchBar />
      <SocialMediaBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          m: {
            xs: "7rem 20px",
            sm: "7rem 25px",
            md: "7rem 30px",
            lg: "7rem 35px",
          },
          px: { xs: 2, sm: 2, md: 5, lg: 10 },
        }}
      >
        <Typography
          className="responsive_fontsize24"
          sx={{
            fontWeight: 700,
            fontFamily: "roboto serif",
            textTransform: "uppercase",
          }}
        >
          Non-Veg Items
        </Typography>
        <ItemList
          displayedItems={displayedItems}
          isMobile={isMobile}
          sliderSettings={sliderSettings}
        />
        {!isMobile && (
          <PaginationControls
            filteredItems={filteredItems}
            displayedItems={displayedItems}
            itemsPerPage={itemsPerPage}
            handleMoreClick={handleMoreClick}
            handleLessClick={handleLessClick}
          />
        )}
      </Box>
    </>
  );
}

export default NonVegItems;
