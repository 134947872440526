const extraFood= [
    {
        id: 1,
        image: "https://img.freepik.com/premium-photo/fried-rice-bowl-dark-background_666745-575.jpg",
        name: "Fried Rice",
        price: "NRS 100",
        offerEndTime: 7 // Offer ends in 7 hours
    },
    {
        id: 2,
        image: "https://www.shutterstock.com/image-photo/chettinad-fish-curry-karnataka-india-600nw-1616977492.jpg",
        name: "Fish Curry",
        price: "NRS 350",
        offerEndTime: 6 // Offer ends in 6 hours
    },
    {
        id: 3,
        image: "https://static.vecteezy.com/system/resources/thumbnails/038/970/613/small_2x/ai-generated-spicy-chicken-biryani-cuisine-in-a-shiny-silver-bowl-authentic-indian-food-serving-fancy-food-in-a-restaurant-photo.jpg",
        name: "Chicken Biryani",
        price: "NRS 150",
        offerEndTime: 5 // Offer ends in 5 hours
    },
    {
        id: 4,
        image: "https://images.herzindagi.info/image/2024/Feb/best-momos.jpg",
        name: "Mo-Mo",
        price: "NRS 180",
        offerEndTime: 4 // Offer ends in 4 hours
    }
];
export default extraFood;
