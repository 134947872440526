import React from "react";
import { Box } from "@mui/material";
import AlignOrderList from "./align_order_list";
import { useSelector } from "react-redux";
import "./CustomScrollbar.css";

function OrderedCardList() {
  const orderedList = useSelector((state) => state.cart.items);

  return (
    <Box className="custom-scrollbar" sx={{ maxHeight: 280, maxWidth: 1000, overflowY: "auto" }}>
      {orderedList.map((orderedFood) => (
        <AlignOrderList key={`${orderedFood.id}-${orderedFood.subcategory}`}
         orderedFood={orderedFood} />
      ))}
    </Box>
  );
}

export default OrderedCardList;
