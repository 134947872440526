import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import spoon from "../../../assets/spoon.png";
import foodData from "./offerTodayDataset";
import {
  addToCheckout,
  moveCheckoutToCart,
} from "../../../redux/slices/cartSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

function HomeSecondSection() {
  const item = foodData[0];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const handleAddToCart = (subcategoryItem) => {
    const itemToAdd = {
      ...item,
      subcategory: subcategoryItem,
    };
    dispatch(addToCheckout(itemToAdd));
    navigate("/checkout");
    toast.success(`Added ${subcategoryItem} to your checkout`);
  };
  useEffect(() => {
    if (location.pathname !== "/checkout") {
      dispatch(moveCheckoutToCart());
    }
  }, [location.pathname, dispatch]);


  return (
    <>
      <Box sx={{ mb: { xs: 5, sm: 10, md: 20 } }}>
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              width: "40%",
              height: "90%",
              background: "rgba(255, 238, 85, 0.50)",
              filter: "blur(151px)",
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              position: "relative",
              flexDirection: { xs: "column", md: "row" },
              mt: { xs: 3, sm: 5, md: 10, lg: 5 },
              gap: { xs: 5, sm: 7, md: 10, lg: 25 },
            }}
          >
            <Box
              sx={{
                height: { xs: "75%", sm: "80%", md: "70%" },
                width: { xs: "75%", sm: "80%", md: "60%" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                ml: { xs: 0, sm: 0, md: 5, lg: 1 },
              }}
            >
              <img
                src={item.image}
                alt={item.subcategory[0]}
                style={{
                  width: "70%",
                  height: "auto",
                  borderRadius: "50%",
                }}
              />
            </Box>

            <Box sx={{ textAlign: "center", mt: { xs: "1rem", md: "0" } }}>
              <Typography
                className="responsive_fontsize18"
                sx={{ color: "#5E4915" }}
              >
                25% off Today
              </Typography>
              <Typography
                className="responsive_fontsize28"
                sx={{ fontWeight: "700" }}
              >
                {item.subcategory[0]}
              </Typography>
              <Typography className="responsive_fontsize18">
                {item.detail}
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                mt="1rem"
                className="responsive_fontsize18"
              >
                <Typography sx={{ fontSize: "1rem" }}>
                  {`${item.price[0]} ${item.price[1]}`}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => handleAddToCart(item.subcategory[0])}
                  sx={{
                    backgroundColor: "#5E4915",
                    color: "white",
                    textTransform: "capitalize",
                    fontWeight: 400,
                    padding: "0.5rem 1.5rem",
                    fontSize: "1rem",
                    mt: "0.75rem",
                    "&:hover": {
                      backgroundColor: "#4a3911",
                    },
                  }}
                >
                  Order Now
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            mt: -18,
            ml: 10,
          }}
        >
          <Box sx={{ width: { xs: "50%", md: "100%" }, height: "4rem" }}>
            <img src={spoon} alt="Spoon" width="100%" />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default HomeSecondSection;
