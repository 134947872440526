import React from 'react';
import {
    Box, 
    Grid,
    Typography
} from '@mui/material'

function CustomerConfirmationInfo() {
  return (
    <div>
      <Typography className="responsive_fontsize20" fontWeight="bold">
            Your info:
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}>
            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">Name</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">
                  Nabina Thapa
                </Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">
                  Location
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">
                  M8PH+M8 Kathmandu, Nepa
                </Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">
                  Phone{" "}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">
                  9868823474
                </Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">Email</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">
                  nabeenasinjalee@gmail.com
                </Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">
                  Delivery Time
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">
                  Around 2:02
                </Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">
                  Special Instructions
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">
                  Turn left from the machhindra bahal next galli
                  can't eat fried onions and garlics
                  not required sauces
                </Typography>
              </Grid>
            </Grid>
          </Box>
    </div>
  )
}

export default CustomerConfirmationInfo
