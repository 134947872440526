import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menus: [], // Array of menus
  selectedMenus: [], // Initialize selectedMenus as an empty array
  currentMenu: null, // Currently selected menu
  openMenuNameDialog: false, // State to track if the menu name dialog is open
  confirmDeleteDialog: false, // State to track if the delete confirmation dialog is open
  deleteMenuName: '', // Name of the menu to be deleted
  menuName: '', // Name of the new menu
  anchorElId: null, // ID of the element to anchor the popover
  openPopover: false, // State to track if the popover is open
  selectedCategory: 'Chowmein',
  foodItemToAdd: null,
  popoverID: null,
  newDate: "",
  newTime: "",
  location: { lat: null, lon: null },
  menuDates: [],
  menuTimes: [],
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    addMenu: (state, action) => {
      const { menuName } = action.payload; // Destructure payload to extract menuName and item
      const newMenu = { menuName, selectedItems: [] };
      state.menus.push(newMenu);
      state.selectedMenus.push(newMenu);
      // if (item) {
      //   state.selectedMenus[state.selectedMenus.length - 1].selectedItems.push(item);
      // }
    },
    updateMenu: (state, action) => {
      const { menuName, item } = action.payload;
      // const { menuName, item } = action.payload;
      const newMenu = { menuName, selectedItems: [] };
      state.menus.push(newMenu);
      state.selectedMenus.push(newMenu);
      // const menu = state.menus.find(menu => menu.menuName === menuName);
      // Add the item to the newly created menu
      if (item) {
        state.selectedMenus[state.selectedMenus.length - 1].selectedItems.push(item);
      }
    },
    deleteMenu: (state, action) => {
      const menuName = action.payload;
      // Filter out the menu from both menus and selectedMenus
      state.menus = state.menus.filter(menu => menu.menuName !== menuName);
      state.selectedMenus = state.selectedMenus.filter(menu => menu.menuName !== menuName);
      // Reset currentMenu if it was deleted
      if (state.currentMenu?.menuName === menuName) {
        state.currentMenu = null;
      }
    },
    addItemToMenu: (state, action) => {
      const { menuName, item } = action.payload;
      const menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (menu) {
          const existingItem = menu.selectedItems.find(i => i.id === item.id);
          if (!existingItem) {
              menu.selectedItems.push(item); // Add item only if it's not already in the menu
          } else {
              existingItem.quantity += 1; // Increment quantity if the item already exists
          }
      } else {
        // If the menu does not exist, create it and add the item
        const newMenu = { menuName, selectedItems: [item] };
        state.selectedMenus.push(newMenu);
        state.menus.push(newMenu);
      }
  },
  
    incrementMenuItemQuantity: (state, action) => {
      const { menuName, itemId } = action.payload;
      const menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (menu) {
        const item = menu.selectedItems.find(i => i.id === itemId);
        if (item) {
          item.quantity += 1;
        }
      }
    },
    decrementMenuItemQuantity: (state, action) => {
      const { menuName, itemId } = action.payload;
      const menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (menu) {
        const item = menu.selectedItems.find(i => i.id === itemId);
        if (item && item.quantity > 1) {
          item.quantity -= 1;
        }
      }
    },
    removeItemFromMenu: (state, action) => {
      const { menuName, itemId } = action.payload;
      const menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (menu) {
        menu.selectedItems = menu.selectedItems.filter(i => i.id !== itemId);
      }
    },
    setAnchorElId: (state, action) => {
      state.anchorElId = action.payload; // Store element ID
    },
    openPopover: (state, action) => {
      const { popoverID, anchorElId, foodItem } = action.payload;
      state.openPopover = true;
      state.popoverID = popoverID;
      state.anchorElId = anchorElId;
      state.foodItemToAdd = foodItem;
    },
    closePopover: (state) => {
      state.openPopover = false;
      state.anchorElId = null;
      state.popoverID = null;
    },
    openMenuNameDialog: (state) => {
      state.openMenuNameDialog = true;
    },
    closeMenuNameDialog: (state) => {
      state.openMenuNameDialog = false;
    },
    openConfirmDeleteDialog: (state, action) => {
      state.confirmDeleteDialog = true;
      state.deleteMenuName = action.payload;
    },
    closeConfirmDeleteDialog: (state) => {
      state.confirmDeleteDialog = false;
    },
    setMenuName: (state, action) => {
      state.menuName = action.payload;
    },
    setDeleteMenuName: (state, action) => {
      state.deleteMenuName = action.payload;
    },
    setCurrentMenu: (state, action) => {
      state.currentMenu = action.payload;
    },
    createMenuFromItems: (state, action) => {
      const { menuName, selectedItems } = action.payload;
      const menu = state.selectedMenus.find(menu => menu.menuName === menuName);
      if (menu) {
        menu.items = [...menu.items, ...selectedItems];
      }
    },
    setFoodItemToAdd: (state, action) => {
      state.foodItemToAdd = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setSelectedMenus: (state, action) => {
      state.selectedMenus = action.payload;
    }
  },
});

export const {
  addMenu,
  updateMenu,
  deleteMenu,
  addItemToMenu,
  removeItemFromMenu,
  incrementMenuItemQuantity,
  decrementMenuItemQuantity,
  openPopover,
  closePopover,
  openMenuNameDialog,
  closeMenuNameDialog,
  openConfirmDeleteDialog,
  closeConfirmDeleteDialog,
  setMenuName,
  setDeleteMenuName,
  setCurrentMenu,
  createMenuFromItems,
  setAnchorElId,
  setSelectedCategory,
  setFoodItemToAdd,
  setSelectedMenus
} = menuSlice.actions;

export default menuSlice.reducer;