import React, { useRef, useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import {DirectionsRenderer, GoogleMap, Marker, Circle, useLoadScript } from "@react-google-maps/api";
import ButtonComponent from "../../components/ui/buttons/button_component"; // Import ButtonComponent
import SearchBoxComponent from "./searchBoxComponent";
import OrderNotificationSystem from "../../components/order_confirmation/dialog_confirm/order_notification_system"; // Import OrderNotificationSystem
import { geocodeLocation, calculateDistance } from "../../utils/mapUtils"; // Utility functions

const center = { lat: 27.7172, lng: 85.324 };
const LIBRARIES = ["places"];
const registeredCustomers = [
  { id: 1, name: "John Doe", location: { lat: 27.7174, lng: 85.324 } },
  { id: 2, name: "Jane Smith", location: { lat: 27.7192, lng: 85.3256 } },
];

// Map Component
const MapComponent = ({ markerPosition, directionsResponse, destinationPosition, setMap, marker }) => (
  <GoogleMap
    center={markerPosition || center}
    zoom={12}
    mapContainerStyle={{ width: "100%", height: "100%" }}
    onLoad={(map) => setMap(map)}
  >
    {markerPosition && <Marker position={markerPosition} />}
    {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
    {destinationPosition && (
      <Circle
        center={destinationPosition}
        radius={1000} // 1 KM radius
        options={{ fillColor: "rgba(0, 0, 255, 0.3)", strokeColor: "rgba(0, 0, 255, 0.6)" }}
      />
    )}
  </GoogleMap>
);

function CustomerLocation() {
  const { isLoaded } = useLoadScript({ googleMapsApiKey: process.env.REACT_APP_BBG_MAP_API_KEY, libraries: LIBRARIES });
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(center);
  const [destinationPosition, setDestinationPosition] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [locationText, setLocationText] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const originRef = useRef(null);
  const destinationRef = useRef(null);

  // Get current position and set location
  useEffect(() => {
    if (navigator.geolocation && window.google) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const newPos = { lat: latitude, lng: longitude };
        setMarkerPosition(newPos);
        geocodeLocation(newPos, setLocationText, map, setMarker);
      });
    }
  }, [map]);

  const handleManualLocationChange = (event) => {
    setLocationText(event.target.value);
    if (event.target.value.trim() === "") {
      setMarkerPosition(center);
      setDirectionsResponse(null);
    }
  };

  const handleManualLocationUpdate = () => {
    geocodeLocation(locationText, setLocationText, map, setMarker);
  };

  const calculateRoute = async () => {
    const origin = originRef.current.value;
    const destination = destinationRef.current.value;
    if (!origin || !destination) return;
    setDirectionsResponse(null);
    const directionsService = new window.google.maps.DirectionsService();
    const results = await directionsService.route({
      origin,
      destination,
      travelMode: window.google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
    const destinationPos = results.routes[0].legs[0].end_location;
    setDestinationPosition(destinationPos);
    notifyNearbyCustomers(destinationPos);
  };

  const notifyNearbyCustomers = (destination) => {
    const nearbyCustomers = registeredCustomers.filter((customer) => {
      return calculateDistance(destination.lat(), destination.lng(), customer.location.lat, customer.location.lng) <= 1;
    });
    if (nearbyCustomers.length > 0) {
      setAlertMessage("Someone has placed an order nearby. Would you like to place your own order?");
      setShowAlert(true);
    }
  };

  const clearRoute = () => {
    setDirectionsResponse(null);
    setDistance("");
    setDuration("");
    if (originRef.current) originRef.current.value = "";
    if (destinationRef.current) destinationRef.current.value = "";
  };

  const openGoogleMapsApp = () => {
    const origin = originRef.current.value;
    const destination = destinationRef.current.value;
    if (origin && destination) {
      const url = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
        origin
      )}&destination=${encodeURIComponent(destination)}&travelmode=driving`;
      window.open(url, "_blank");
    }
  };

  if (!isLoaded) return <Typography>Loading map...</Typography>;

  return (
    <Box sx={{ m: { xs: "7rem 0 0.5rem 0", lg: "7rem 0 1rem 0" }, display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
      <Box sx={{ border: "1px solid black", padding: "2rem 1rem 0.5rem 1rem", width: { xs: "100%", md: "60%" }, display: "flex", flexDirection: "column" }}>
        <Grid container spacing={2}>
        <Grid item xs={12} md={8} sx={{ display: "flex", gap: "1rem", justifyContent: "flex-end" }}>
          <SearchBoxComponent
            originRef={originRef}
            destinationRef={destinationRef}
            locationText={locationText}
            handleLocationChange={handleManualLocationChange}
            handleLocationUpdate={handleManualLocationUpdate}
          />
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: "flex", gap: "1rem", justifyContent: "flex-end" }}>
            <ButtonComponent onClick={calculateRoute} disabled={!originRef.current || !destinationRef.current}>
              Search
            </ButtonComponent>
            <ButtonComponent onClick={clearRoute}>
              Clear Route
            </ButtonComponent>
            {/* <IconButton aria-label="clear" onClick={clearRoute}>
              <RemoveCircle />
            </IconButton> */}
          </Grid>
        </Grid>

        {distance && duration && (
          <Box sx={{ display: { xs: "column", md: "flex" }, justifyContent: "space-between", alignItems: "center", mt: 1 }}>
            <Box>
              <Typography>Distance: {distance}</Typography>
              <Typography>Duration: {duration}</Typography>
            </Box>
            <ButtonComponent onClick={openGoogleMapsApp} sx={{ width: "120px" }}>
              Open in Maps
            </ButtonComponent>
          </Box>
        )}

        <Box sx={{ height: "600px", width: "100%", marginTop: "1rem" }}>
          <MapComponent
            markerPosition={markerPosition}
            directionsResponse={directionsResponse}
            destinationPosition={destinationPosition}
            setMap={setMap}
            marker={marker}
          />
        </Box>
        {showAlert && <OrderNotificationSystem message={alertMessage} onClose={() => setShowAlert(false)} />}
      </Box>
    </Box>
  );
}

export default CustomerLocation;
