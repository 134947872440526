import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Avatar,
  Typography,
  Button,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import SearchBar from "../../../components/search_bar/search_bar_main";
import SocialMediaBar from "../../../components/social_media_bar/social_media_bar_main";
import axios from "axios";
import SettingPage from "./setting/settingPage";

function ProfileLoadPage() {
  const [profilePicture, setProfilePicture] = useState(
    () =>
      localStorage.getItem("profilePicture") ||
      "https://plus.unsplash.com/premium_photo-1661481717870-f3894995f79b?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  );
  const [numberOfOrders] = useState(10);
  const [menusMade] = useState(5);
  const [userName, setUserName] = useState("");
  const [openOrders, setOpenOrders] = useState(false);
  const [openMenus, setOpenMenus] = useState(false);
  const [customerMadeMenus] = useState([
    "abc",
    "xyz",
    "def",
  ]); // Example customer made menus
  const fileInputRef = useRef(null);

  useEffect(() => {
    // Load profile picture from localStorage when the component mounts
    const savedProfilePicture = localStorage.getItem("profilePicture");
    if (savedProfilePicture) {
      setProfilePicture(savedProfilePicture);
    }
  }, []);

  useEffect(() => {
    const fetchProfile = async () => {
      const customerId = localStorage.getItem('customerId'); // Retrieve customer ID from localStorage
      const token = localStorage.getItem('authToken');
      if (!customerId || !token) {
        console.error('Customer ID or token is missing');
        console.log("customer id and token is not found")
        return;
      }
      try {
        const response = await axios.get(`http://localhost:8001/bbgapi/users/${customerId}`, {
          headers: {
            'Authorization': `Bearer ${token}` // Include token here
          }
        });
        // console.log("profile data info", response.data.data);
        const profile = response.data.data;
        setUserName(profile.customer_name);
        // Set other profile data as needed
      } catch (error) {
        console.error("Error fetching profile data:", error);
        if (error.response) {
          console.error('Error data:', error.response.data);
          console.error('Error status:', error.response.status);
          console.error('Error headers:', error.response.headers);
        } else {
          console.error('Error message:', error.message);
        }
      }
    };
  
    fetchProfile();
  }, []);
  


  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newProfilePicture = e.target.result;
        setProfilePicture(newProfilePicture);

        // Save the profile picture to local storage
        localStorage.setItem("profilePicture", newProfilePicture);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditProfileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleNumberOfOrdersClick = () => {
    setOpenOrders(!openOrders);
    if (!openOrders) {
      setOpenMenus(false);
      // Here you might fetch or manage orders if needed
    }
  };

  const handleMenusMadeClick = () => {
    setOpenMenus(!openMenus);
    if (!openMenus) {
      setOpenOrders(false);
    }
  };

  return (
    <>
      <Box sx={{ mt: 12 }}>
        <SearchBar />
        <SocialMediaBar />
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "10px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box sx={{ position: "relative", display: "inline-block" }}>
                <Avatar
                  alt="Profile Picture"
                  src={profilePicture}
                  sx={{ width: 120, height: 120, marginBottom: 2 }}
                />

                {/* Hidden input for uploading the profile picture */}
                <input
                  ref={fileInputRef} // Reference to the input element
                  accept="image/*"
                  style={{ display: "none" }}
                  id="icon-button-file"
                  type="file"
                  onChange={handleProfilePictureChange}
                />

                <label htmlFor="icon-button-file">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    sx={{
                      position: "absolute",
                      bottom: 4,
                      right: 4,
                      backgroundColor: "white",
                      borderRadius: "50%",
                      p: 0.5,
                      boxShadow: 1,
                    }}
                  >
                    <PhotoCamera />
                  </IconButton>
                </label>
              </Box>
              <Typography
                className="responsive_fontsize25"
                variant="h5"
                sx={{ fontWeight: "600", marginBottom: 1.2 }}
              >
                {userName}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#281010",
                  color: "white",
                  fontSize: "1rem",
                  fontFamily: "Roboto Serif",
                  padding: "0.2rem 1.2rem",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#1f0e0e",
                  },
                  // Responsive styles
                  "@media (max-width: 600px)": {
                    fontSize: "0.7rem",
                    padding: "0.1rem 0.4rem",
                  },
                  "@media (max-width: 400px)": {
                    fontSize: "0.5rem",
                    padding: "0.1rem 0.3rem",
                  },
                }}
                onClick={handleEditProfileClick} // This triggers the hidden file input's click
              >
                Edit Profile
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} sx={{ marginTop: "10px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleNumberOfOrdersClick}
            >
              <Typography className="responsive_fontsize20" variant="body1">
                {numberOfOrders}
              </Typography>
              <Typography className="responsive_fontsize20" variant="body1">
                Number of orders
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} sx={{ marginTop: "10px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleMenusMadeClick}
            >
              <Typography className="responsive_fontsize20" variant="body1">
                {menusMade}
              </Typography>
              <Typography className="responsive_fontsize20" variant="body1">
                Menus Made
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item xs={12} md={9}>
            {openOrders && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" sx={{ textAlign: "center", mb: 1 }}>
                  Orders Made
                </Typography>
                <List
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {/* You can add orders here if needed */}
                </List>
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item xs={12} md={9}>
            {openMenus && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" sx={{ textAlign: "center", mb: 1 }}>
                  Customer Made Menus
                </Typography>
                <List
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {customerMadeMenus.map((menu, index) => (
                    <ListItem
                      key={index}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <ListItemText primary={menu} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Grid>
        </Grid>
            <SettingPage/>
      </Box>
    </>
  );
}

export default ProfileLoadPage;
