import React, {useRef} from "react";
import PropTypes from 'prop-types';
import { Box, IconButton, Typography } from "@mui/material";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import AddIcon from "@mui/icons-material/Add";
import SearchBar from "src/components/search_bar/search_bar_main";
import SocialMediaBar from "src/components/social_media_bar/social_media_bar_main";
import { useDispatch, useSelector } from "react-redux";
import FavoritePopover from "src/views/menu/components/make_menu/Popover_make_menu";
import {
  openPopover as openPopoverAction,
  closePopover,
  setAnchorElId,
  setMenuName,
  setDeleteMenuName,
  openMenuNameDialog as MenuDialog,
  closeMenuNameDialog,
  openConfirmDeleteDialog,
  closeConfirmDeleteDialog,
} from "src/redux/slices/menuSlice";
import MenuDialogs from "../FoodCard/MenuCardDialog";

const Header = () => {
  const dispatch = useDispatch();
  const anchorElRef = useRef(null);
  const {openPopover,
    selectedMenus,
    openMenuNameDialog,
    menuName,
    confirmDeleteDialog,
    deleteMenuName, popoverID} = useSelector((state) => state.menus);

  
  const handleOpenPopover = (event) => {
    console.log('error')
    anchorElRef.current = event.currentTarget;
    dispatch(setAnchorElId(event.currentTarget)); // Store a reference or ID if needed
    dispatch(openPopoverAction({ anchorEl: event.currentTarget, popoverID: 'header' }));
    console.log("popover", popoverID)

  };

  const handleClosePopover = () => {
    dispatch(closePopover());
    anchorElRef.current = null; // Clear anchorEl when closing

  };
  const handleOpenMenuNameDialog = () => {
    dispatch(MenuDialog());
  };

  const handleCloseMenuNameDialog = () => {
    dispatch(closeMenuNameDialog());
  };

  const handleMenuNameSubmit = () => {
    // Implement menu name submit logic here
    dispatch(setMenuName(menuName)); 
    // You might want to add an action to add the menu
    handleCloseMenuNameDialog();
  };

  const handleConfirmDelete = () => {
    // Implement delete logic here
    dispatch(setDeleteMenuName(deleteMenuName));
    handleCloseConfirmDeleteDialog();
  };

  const handleCloseConfirmDeleteDialog = () => {
    dispatch(closeConfirmDeleteDialog());
  };

  return (
    <>
      <SearchBar />
      <SocialMediaBar />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          m: {
            xs: "5rem 0 0.5rem 0 ",
            sm: "5rem 0 0.5rem 0",
            md: "5rem 0 0.5rem 0 ",
            lg: "5rem 0 1rem 0",
          }
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", md: "column" },
            alignItems: "center",
            gap: { xs: 2, md: 0 },
            mt: 5,
          }}
        >
          <Box sx={{ display: "flex", mb: { sm: 0, md: 2 }, ml: 10 }}>
            <IconButton
              sx={{
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
            >
              <FastfoodIcon
                className="responsive_fontsize32"
                sx={{ color: "#EAB42A" }}
              />
            </IconButton>

            <Typography
              className="responsive_fontsize32"
              sx={{ color: "green" }}
            >
              Make Your Menu
            </Typography>
            <IconButton
              onClick={handleOpenPopover}
              sx={{
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                ml: 2,
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
            >
              <AddIcon sx={{ color: "green" }} />
            </IconButton>
            <FavoritePopover
             anchorEl={anchorElRef.current}
             open={openPopover && popoverID === 'header'}
             onClose={handleClosePopover}
             onAddMenuName={handleOpenMenuNameDialog}
             handleMenuSelect={(menuName) => {/* Implement menu selection logic here */}}
             openDeleteConfirmationDialog={(menuName) => {
               dispatch(openConfirmDeleteDialog(menuName));
             }}
             menuNames={selectedMenus.map((menu) => menu.menuName)}
             popoverID="header"
            />
          </Box>
        </Box>
      </Box>

      <MenuDialogs
        openMenuNameDialog={openMenuNameDialog}
        setOpenMenuNameDialog={handleCloseMenuNameDialog}
        menuName={menuName}
        setMenuName={(name) => dispatch(setMenuName(name))}
        handleMenuNameSubmit={handleMenuNameSubmit}
        confirmDeleteDialog={confirmDeleteDialog}
        setConfirmDeleteDialog={handleCloseConfirmDeleteDialog}
        deleteMenuName={deleteMenuName}
        confirmDelete={handleConfirmDelete}
      />
    </>
  );
};

Header.propTypes = {
  openPopover: PropTypes.bool.isRequired,
  handleClosePopover: PropTypes.func.isRequired,
  handleOpenMenuNameDialog: PropTypes.func.isRequired,
  handleMenuSelect: PropTypes.func.isRequired,
  handleDeleteMenu: PropTypes.func.isRequired,
  selectedMenus: PropTypes.arrayOf(
    PropTypes.shape({
      menuName: PropTypes.string.isRequired
    })
  ).isRequired,
};

export default Header;
