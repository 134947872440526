import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./slices/homeSlice";
import cartReducer from "./slices/cartSlice";
import loginReducer from "./reducers/login_reducers";
import menuReducer from "./slices/menuSlice"
import confirmOrderReducer from "./reducers/confirmOrderReducer";
import foodReducer from '../components/foodDescriptionPage/store/reducer';
import customerLocationReducer from './slices/customerLocation'
export const store = configureStore({
  reducer: {
    home: homeReducer,
    cart: cartReducer,
    login: loginReducer,
    menus : menuReducer,
    orderconfirm: confirmOrderReducer,
    food: foodReducer,
    customerLocation: customerLocationReducer
  },
});
export default store;
