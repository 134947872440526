import React from "react";
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchBar from "src/components/search_bar/search_bar_main";
import SocialMediaBar from "src/components/social_media_bar/social_media_bar_main";
import faqsData from "./faqsData";

const FAQSection = () => {
  return (
    <>
      <SearchBar />
      <SocialMediaBar />
      <Grid container justifyContent="center" sx={{ mt: "10rem", mb: "7rem" }}>
        <Grid item xs={8} md={8}>
          <Typography
            variant="h5"
            className="responsive_fontsize28"
            sx={{
              fontWeight: "bold",
              color: "#166F2A",
              textDecoration: "none",
              cursor: "pointer",
              mb: 3,
              textAlign: "center",
            }}
          >
            Frequently Asked Questions
          </Typography>
          {faqsData.map((faq, index) => (
            <Accordion key={index} sx={{ mb: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                <Typography
                  variant="subtitle1"
                  className="responsive_fontsize24"
                  sx={{ fontWeight: "bold" }}
                >
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" className="responsive_fontsize24">
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default FAQSection;
