const offerToday= [
  {
    id: 4,
    image:
      "https://static.vecteezy.com/system/resources/previews/025/269/710/original/fried-rice-with-ai-generated-free-png.png",
    subcategory: ["Chicken Fried Rice", "Mutton Fried Rice", "Veg Fried Rice"],
    detail: "Aromatic basmati rice with tender chicken pieces and spices.",
    price: ["Rs.", "299"],
    eventStartDate: "2024-08-02",
    eventEndDate: "2024-08-10",
    eventStartTime: "7:00 AM",
    eventEndTime: "10:00 PM",
    occasion: "Dashain Special",
    category: "Fried Rice",
    classification: [
      {
        classified: "veg",
        subcategory: ["mushroom", "cheese", "veg"],
      },
      {
        classified: "non-veg",
        subcategory: ["chicken", "Mutton"],
      },
      {
        classified: "platter", // Added platter classification
        subcategory: [""],
      },
    ],
    ingredients: [
      "Basmati Rice",
      "Chicken",
      "Mutton",
      "Vegetables",
      "Soy Sauce",
      "Garlic",
      "Ginger",
    ],
  },
];

export default offerToday;