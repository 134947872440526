import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Header from "./cardListingHeader";
import CategorySection from "./categorySection";
import FoodItemsGrid from "./foodItemsGrid";
import MenuContainer from "./menuContainer";
import Dataset from "../../../../components/offerSection/dataItems";
import {
  setAnchorElId,
  closePopover,
  setSelectedCategory,
} from "../../../../redux/slices/menuSlice";

function MakeMenu({ foodOrdered }) {
  const dispatch = useDispatch();
  const {
    selectedMenus,
    currentMenu,
    openMenuNameDialog,
    confirmDeleteDialog,
    deleteMenuName,
    openPopover,
    selectedCategory,
  } = useSelector((state) => state.menus);

  const categories = [...new Set(Dataset.map((item) => item.category))];
  const [ setCurrentMenuIndex] = useState(0); // Ensure initial index is valid

  const handleSetCurrentMenuIndex = (index) => {
    if (index >= 0 && index < selectedMenus.length) {
      // Validate index
      setCurrentMenuIndex(index);
    } else {
      console.error("Invalid menu index:", index);
    }
  };

  return (
    <>
      <Header
        setAnchorEl={(el) => dispatch(setAnchorElId(el))}
        openPopover={openPopover}
        handleClosePopover={() => dispatch(closePopover())}
        handleOpenMenuNameDialog={() => dispatch(openMenuNameDialog())}
        selectedMenus={selectedMenus}
      />
      <CategorySection
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={(category) =>
          dispatch(setSelectedCategory(category))
        }
      />
      <FoodItemsGrid
        selectedCategory={selectedCategory}
        selectedMenus={selectedMenus}
        currentMenu={currentMenu}
      />
      <MenuContainer
        selectedMenus={selectedMenus}
        currentMenu={currentMenu}
        confirmDeleteDialog={confirmDeleteDialog}
        deleteMenuName={deleteMenuName}
        setCurrentMenuIndex={handleSetCurrentMenuIndex}
        foodOrdered={foodOrdered}
      />
    </>
  );
}

MakeMenu.propTypes = {
  foodOrdered: PropTypes.array.isRequired,
};

MakeMenu.defaultProps = {
  foodOrdered: [],
};

export default MakeMenu;
