import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

function CardIngredients({
  food,
  sliderIndex,
  ingredientAnimateClass,
  setIngredientAnimateClass,
}) {
  const animationDelay = 200;

  useEffect(() => {
    // Timeout to trigger the animation after the component re-renders
    const timer = setTimeout(() => {
      setIngredientAnimateClass("ingredient-enter");
    }, 50);

    // Clear timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [sliderIndex, setIngredientAnimateClass]);

  // Ensure ingredients is always an array
  const ingredients = Array.isArray(food?.ingredients) ? food.ingredients : [];

  return (
    <Box
      sx={{
        width: "30%",
        position: "relative",
        display: { md: "block", xs: "none" },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          filter: "blur(113px)",
        }}
      ></Box>
      <Box sx={{ mt: "10%" }}>
        <Typography
          className="responsive_fontsize24"
          sx={{
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          Ingredients
        </Typography>
        <Box
          className="responsive_fontsize18"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          {ingredients.map((ingredient, index) => {
            const delay = ingredientAnimateClass ? index * animationDelay : 0;
            return (
              <Typography
                key={index}
                sx={{
                  fontSize: "1.25rem",
                  fontWeight: "400",
                  textAlign: "center",
                  opacity: ingredientAnimateClass ? 0 : 1,
                  animation: ingredientAnimateClass
                    ? `slideInFromRight 0.5s ease-out ${delay}ms forwards`
                    : "none",
                }}
              >
                {ingredient}
              </Typography>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default CardIngredients;
