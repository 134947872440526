import React from "react";
import { Box } from "@mui/material";
import MenuName from "./menuNames";
import MakeMenuList from "../makeMenuItems/selectedItems";
import DeleteMenuPopover from "./deleteMenuPopover";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { closeConfirmDeleteDialog,setDeleteMenuName, setCurrentMenu, deleteMenu } from "src/redux/slices/menuSlice";

const MenuContainer = ({
  confirmDeleteDialog,
  deleteMenuName,
  confirmDelete,
  foodOrdered,
  setSelectedMenus,
  setCurrentMenuIndex,
}) => {
  const dispatch = useDispatch();

  const { selectedMenus, currentMenu } = useSelector((state) => state.menus);
  // Handle menu selection
  const handleMenuSelection = (menuName) => {
    dispatch(setCurrentMenu(menuName));
    setCurrentMenuIndex(selectedMenus.findIndex(menu => menu.menuName === menuName));
    console.log('menu name in menu container: ', menuName)
  };

 // Implement delete logic here
 const handleDeleteMenu = () => {
  if (deleteMenuName) {
    dispatch(deleteMenu(deleteMenuName)); // Dispatch deleteMenu action
    dispatch(setDeleteMenuName('')); // Reset deleteMenuName state
    handleCloseDeleteMenu();
    console.log("Deleted menu:", deleteMenuName);
  }
};


  const handleCloseDeleteMenu = () => {
    dispatch(closeConfirmDeleteDialog(currentMenu));
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
        ml: 2,
      }}
    >
      {selectedMenus.length > 0 && (
        <MenuName
          selectedMenus={selectedMenus}
          handleMenuSelection={handleMenuSelection} 
          handleDeleteMenu={handleDeleteMenu}
          open={confirmDeleteDialog}
        />
      )}
      {currentMenu && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <MakeMenuList
            currentMenu={currentMenu}
            selectedMenus={selectedMenus}
            foodOrdered={foodOrdered}
            setSelectedMenus={setSelectedMenus}
            setCurrentMenuIndex={setCurrentMenuIndex}
          />
        </Box>
      )}
      <DeleteMenuPopover
        open={confirmDeleteDialog}
        handleClose={handleCloseDeleteMenu}
        handleDeleteMenu={handleDeleteMenu}
        confirmDelete={confirmDelete}
        deleteMenuName={deleteMenuName}
      />
    </Box>
  );
};

MenuContainer.propTypes = {
  currentMenu: PropTypes.object,
  handleMenuSelect: PropTypes.func.isRequired,
  handleDeleteMenu: PropTypes.func.isRequired,
  confirmDeleteDialog: PropTypes.bool.isRequired,
  deleteMenuName: PropTypes.string.isRequired,
  confirmDelete: PropTypes.func.isRequired,
  foodOrdered: PropTypes.array.isRequired,
  setSelectedMenus: PropTypes.func.isRequired,
  setCurrentMenuIndex: PropTypes.func.isRequired,
};

export default MenuContainer;
