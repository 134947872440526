import React from "react";
import SearchBar from "../../../components/search_bar/search_bar_main";
import SocialMediaBar from "../../../components/social_media_bar/social_media_bar_main";
import ContactWay from "./contactWay";
import contactData from "./contactDataSet";
import { Grid, Box, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomDivider = styled(Divider)(({ theme }) => ({
  width: "30%",
  margin: "auto",
  "&::before, &::after": {
    borderColor: "#EAA017",
    borderWidth: 2,
  },
  [theme.breakpoints.down("md")]: {
    width: "50%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "70%",
  },
}));
function ContactForm() {
  return (
    <>
      <SearchBar />
      <SocialMediaBar />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "10px 0 30px 0",
        }}
      >
        <CustomDivider
          className="responsive_fontsize32"
          sx={{ fontWeight: "600" }}
        >
          Contact Us
        </CustomDivider>
      </Box>
      <Box sx={{ marginBottom: "6px" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={1.3}
            justifyContent="center"
            alignItems="center"
          >
            {contactData.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                <ContactWay content={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <Box component="map">
        <iframe
         title="Google Maps showing BBG Restaurant location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3533.6581515619773!2d85.33012167453415!3d27.666046327313552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1905141b0983%3A0xbd5fda8d1783fc09!2sBBG%20Restaurant!5e0!3m2!1sen!2snp!4v1719458711525!5m2!1sen!2snp"
          width="100%"
          height="400"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>
    </>
  );
}
export default ContactForm;
