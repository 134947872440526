import React, { useState } from "react";
import reviewsData from "./reviewData";
import ReviewsComponent from "./reviewsComponent";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Fade,
  TextField,
  Button
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  Notifications as NotificationsIcon,
  AccountCircle as AccountCircleIcon,
  LocalActivity as LocalActivityIcon,
  Reviews as ReviewsIcon,
} from "@mui/icons-material";

const referredPersons = [
  { name: "John Doe", email: "john.doe@example.com" },
  { name: "Jane Smith", email: "jane.smith@example.com" },
];

function SettingPage() {
  const [expanded, setExpanded] = useState(false);
  const [nestedExpanded, setNestedExpanded] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [showReviews, setShowReviews] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleNestedAccordionChange = (panel) => (event, isExpanded) => {
    setNestedExpanded(isExpanded ? panel : false);
  };

  const handleReviewsClick = () => {
    if (!showReviews) {
      setReviews(reviewsData); // Set reviews from data source (API call, etc.)
    }
    setShowReviews(!showReviews); // Toggle the visibility of the reviews section
  };

  return (
    <>
      <Accordion
        expanded={expanded === "accountsCenter"}
        onChange={handleAccordionChange("accountsCenter")}
        TransitionComponent={Fade}
        transitionDuration={400}
        sx={{
          "& .MuiAccordion-region": {
            height: expanded === "accountsCenter" ? "auto" : 0,
          },
          "& .MuiAccordionDetails-root": {
            display: expanded === "accountsCenter" ? "block" : "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <AccountCircleIcon sx={{ mr: 1 }} />
          <Typography className="responsive_fontsize18">
            Accounts Center
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion
            expanded={nestedExpanded === "password"}
            onChange={handleNestedAccordionChange("password")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="password-content"
              id="password-header"
            >
              <Typography className="responsive_fontsize16">
                Password
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="New Password"
                type="password"
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Confirm Password"
                type="password"
                fullWidth
                sx={{ mb: 2 }}
              />
              <Button variant="contained" color="primary">
                Change Password
              </Button>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={nestedExpanded === "personalDetails"}
            onChange={handleNestedAccordionChange("personalDetails")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="personal-details-content"
              id="personal-details-header"
            >
              <Typography className="responsive_fontsize16">
                Personal Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="responsive_fontsize16">
                View your personal details here...
              </Typography>
              {/* Add logic to fetch and display personal details */}
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={nestedExpanded === "adPreferences"}
            onChange={handleNestedAccordionChange("adPreferences")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="ad-preferences-content"
              id="ad-preferences-header"
            >
              <Typography className="responsive_fontsize16">
                Ad Preferences
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                Accept Ads
              </Button>
              <Button variant="contained" color="secondary">
                Reject Ads
              </Button>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "refer"}
        onChange={handleAccordionChange("refer")}
        TransitionComponent={Fade}
        transitionDuration={400}
        sx={{
          "& .MuiAccordion-region": {
            height: expanded === "refer" ? "auto" : 0,
          },
          "& .MuiAccordionDetails-root": {
            display: expanded === "refer" ? "block" : "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <LocalActivityIcon sx={{ mr: 1 }} />
          <Typography className="responsive_fontsize18">Refer</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {referredPersons.map((person, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Typography className="responsive_fontsize12">
                Name: {person.name}
              </Typography>
              <Typography className="responsive_fontsize12">
                Email: {person.email}
              </Typography>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "notification"}
        onChange={handleAccordionChange("notification")}
        TransitionComponent={Fade}
        transitionDuration={400}
        sx={{
          "& .MuiAccordion-region": {
            height: expanded === "notification" ? "auto" : 0,
          },
          "& .MuiAccordionDetails-root": {
            display: expanded === "notification" ? "block" : "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <NotificationsIcon sx={{ mr: 1 }} />
          <Typography className="responsive_fontsize18">
            Notification
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="responsive_fontsize16">
            Notification details go here.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "reviews"}
        onChange={handleAccordionChange("reviews")}
        TransitionComponent={Fade}
        transitionDuration={400}
        sx={{
          "& .MuiAccordion-region": {
            height: expanded === "reviews" ? "auto" : 0,
          },
          "& .MuiAccordionDetails-root": {
            display: expanded === "reviews" ? "block" : "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4-content"
          id="panel4-header"
          onClick={handleReviewsClick}
        >
          <ReviewsIcon sx={{ mr: 1 }} />
          <Typography className="responsive_fontsize18">Reviews</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {showReviews && (
            <ReviewsComponent reviews={reviews} onClose={handleReviewsClick} />
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "subscription"}
        onChange={handleAccordionChange("subscription")}
        TransitionComponent={Fade}
        transitionDuration={400}
        sx={{
          "& .MuiAccordion-region": {
            height: expanded === "subscription" ? "auto" : 0,
          },
          "& .MuiAccordionDetails-root": {
            display: expanded === "subscription" ? "block" : "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5-content"
          id="panel5-header"
        >
          <ReviewsIcon sx={{ mr: 1 }} />
          <Typography className="responsive_fontsize18">
            Subscription
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="responsive_fontsize16">
            Subscription details here...
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default SettingPage;
