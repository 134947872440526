import React from 'react';
import Slider from "react-slick";
import { Box } from '@mui/material';
import FoodCard from '../FoodCard/FoodCard';

function MobileSlider({ searchedItems }) {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplaySpeed: 2000,
    autoplay: true,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <Box sx={{ display: { xs: "block", sm: "none" } }}>
      <Slider {...sliderSettings}>
        {searchedItems.map(
          (fooditem) =>
            fooditem.subcategory &&
            fooditem.subcategory.map((sub, index) => (
              <Box key={`${fooditem.id}-${sub}-${index}`} sx={{ padding: 1 }}>
                <FoodCard fooditem={fooditem} subcategory={sub} />
              </Box>
            ))
        )}
      </Slider>
    </Box>
  );
}

export default MobileSlider;
