import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import { useDispatch } from "react-redux";
import { addToCheckout, moveCheckoutToCart } from "src/redux/slices/cartSlice";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import CardIngredients from "./card_ingredients";
import CardName from "./card_name";

function HomeCard({ food, sliderIndex, ingredientAnimateClass, setIngredientAnimateClass }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleAddToCheckout = (subcategoryItem) => {
    const itemToAdd = {
      ...food,
      subcategory: subcategoryItem,
    };
    dispatch(addToCheckout(itemToAdd));
    navigate('/checkout');
    toast.success(`Added ${subcategoryItem} to checkout`);
  };

  useEffect(() => {
    if (location.pathname !== "/checkout") {
      dispatch(moveCheckoutToCart());
    }
  }, [location.pathname, dispatch]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { xs: 4, sm: 4 },
        pt:10
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          height: "100%",
          flexDirection: { xs: "column-reverse", sm: "row" },
        }}
      >
        <CardName
          food={food}
          handleAddToCart={() => handleAddToCheckout(food.subcategory[0])}
        />
        <Box
          sx={{
            width: { xs: "60%", md: "auto" },
            height: { xs: "50%", md: "auto" },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src={food.image}
            alt={food.subcategory ? food.subcategory.join(", ") : ""}
            style={{
              width: "70%",
              height: "50%",
              borderRadius: '50%',
            }}
          />
        </Box>
        <CardIngredients
          food={food}
          sliderIndex={sliderIndex}
          ingredientAnimateClass={ingredientAnimateClass}
          setIngredientAnimateClass={setIngredientAnimateClass}
        />
      </Box>
    </Container>
  );
}

export default HomeCard;
