import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import SearchBar from "../search_bar/search_bar_main";
import SocialMediaBar from "../social_media_bar/social_media_bar_main";
import FoodCard from "./desFoodCard";
import IncrementDecrement from "../../views/menu/components/makeMenuItems/IncrementDecrement";
import dataItems from "../../components/offerSection/dataItems";
import IngredientsAndFeedback from "./ingredientsAndFeedback";
import CustomButton from "./customButton";
import {
  addItem,
  addToCheckout,
  moveCheckoutToCart,
} from "src/redux/slices/cartSlice";
import { setRelatedFoodItems, setSelectedFoodItem } from "./store/actions";
import { getSelectedFoodItem} from "./store/selectors";

function Description() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const foodItem = useSelector(getSelectedFoodItem);
  // const relatedFoodItems = useSelector(getRelatedFoodItems);
  const [quantity, setQuantity] = useState(1);
  // const [selectedSubcategory, setSelectedSubcategory] = useState(""); 

  useEffect(() => {
    if (location.state?.foodItem) {
      dispatch(setSelectedFoodItem(location.state.foodItem));
    }
  }, [location.state, dispatch]);

  

  const handleAddToCart = () => {
    const itemToAdd = {
      ...foodItem,
      quantity, // Pass the quantity here
      subcategory: primarySubcategory, // Use the selected subcategory
    };
    dispatch(addItem(itemToAdd));
    toast.success(`Added ${itemToAdd.subcategory} to your cart`);
  };

  const handleAddToCheckout = () => {
    const itemToAdd = {
      ...foodItem,
      quantity, // Pass the quantity here
      subcategory: primarySubcategory, // Use the selected subcategory
    };
    dispatch(addToCheckout(itemToAdd));
    navigate("/checkout");
    toast.success(`Added ${itemToAdd.subcategory} to checkout`);
  };

  useEffect(() => {
    if (location.pathname !== "/checkout") {
      dispatch(moveCheckoutToCart());
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (foodItem) {
      const subcategories = Array.isArray(foodItem.subcategory)
        ? foodItem.subcategory
        : [foodItem.subcategory];
      const keywords = subcategories.flatMap((subcategory) =>
        subcategory.split(" ")
      );
      const relatedItems = dataItems.filter(
        (item) =>
          item.subcategory.some((subcategory) =>
            keywords.some((keyword) =>
              subcategory.toLowerCase().includes(keyword.toLowerCase())
            )
          ) && item.id !== foodItem.id
      );
      dispatch(setRelatedFoodItems(relatedItems));
    }
  }, [foodItem, dispatch]);

  if (!foodItem || !foodItem.image) {
    return <Box>Loading... or No image available</Box>;
  }

  const stars = Array.from({ length: 3 });
  const primarySubcategory = location.state?.subcategory || 
    (Array.isArray(foodItem.subcategory) ? foodItem.subcategory[0] : foodItem.subcategory);

  return (
    <Box>
      <SearchBar />
      <SocialMediaBar />
      <Box sx={{ backgroundColor: "#f5f5f5", py: 3, px: { xs: 2, md: 4 } }}>
        <Grid container spacing={{ xs: 3, md: 6 }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-", md: "flex-end" },
              alignItems: "center",
            }}
          >
            <FoodCard
              sx={{
                margin: "auto",
                maxWidth: { xs: "100%", md: "80%" },
              }}
              foodItem={foodItem}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", sm: "flex-start" },
            }}
          >
            <Typography
              className="responsive_fontsize48"
              sx={{
                color: "#166F2A",
                fontWeight: 500,
                mb: { xs: "10px", md: "20px" },
              }}
            >
              {primarySubcategory}
            </Typography>
            <Typography
              className="responsive_fontsize24"
              sx={{
                display: "flex",
                fontWeight: 400,
                mb: 2,
              }}
            >
              Description
            </Typography>
            <Typography className="responsive_fontsize18" variant="body1">
              {foodItem.detail}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", sm: "flex-start" },
                mt: { xs: "12px", md: "24px" },
                gap: 1.5,
              }}
            >
              <IncrementDecrement
                sx={{ mb: { xs: "8px", md: "16px" } }}
                count={quantity}
                onIncrement={() => setQuantity(quantity + 1)}
                onDecrement={() => setQuantity(Math.max(1, quantity - 1))}
              />
              <Typography sx={{ mb: { xs: "4px", md: "8px" } }}>
                {foodItem.price}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: { xs: "10px", md: "20px" },
                }}
              >
                <CustomButton
                  text="Add to Cart"
                  onClick={handleAddToCart}
                  sx={{
                    width: { xs: "100%", md: "auto" },
                  }}
                />
                <CustomButton
                  text="Order Now"
                  onClick={handleAddToCheckout}
                  sx={{
                    width: { xs: "100%", md: "auto" },
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <IngredientsAndFeedback stars={stars} />
      </Box>
    </Box>
  );
}

export default Description;
