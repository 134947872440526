import { Typography, Box, Grid } from "@mui/material";
import React from "react";
import FoodCard from "src/views/menu/components/FoodCard/FoodCard";
import Slider from "react-slick";
import Dataset from "src/components/offerSection/dataItems";
function HomeThirdSection() {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box>
      <Box>
        <Typography
          sx={{
            fontFamily: "Roboto Serif",
            fontSize: "2rem",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          Popular
        </Typography>
      </Box>
      <Box sx={{ mt: "3.5rem 2rem" }} className="slick__popular">
        <Slider {...settings}>
          {Dataset.map((item) =>
            item.subcategory.map((sub, index) => (
              <Grid item key={`${item.id}-${sub}-${index}`}>
                <Box sx={{ position: "relative" }}>
                  <FoodCard fooditem={item} subcategory={sub} />
                </Box>
              </Grid>
            ))
          )}
        </Slider>
      </Box>
    </Box>
  );
}

export default HomeThirdSection;
