import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import esewa from "../order_list/payment_assets/esewa_logo.png";
import khalti from "../order_list/payment_assets/khalti_logo.png";
import fonepay from "../order_list/payment_assets/fonepay_logo.png";
import mobBanking from "../order_list/payment_assets/mob_banking.png";

function CheckoutPayment() {
  return (
    <Box>
      <Box>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="cash"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="cash"
              control={<Radio />}
              label="Cash on delivery"
            />
            <FormControlLabel
              value="esewa"
              control={<Radio />}
              label={
                <img
                  src={esewa}
                  alt="esewa logo payment selection"
                  style={{ height: "20px", width: "70px" }}
                />
              }
            />
            <FormControlLabel
              value="khalti"
              control={<Radio />}
              label={
                <img
                  src={khalti}
                  alt="khalti logo payment selection"
                  style={{ height: "30px", width: "90px" }}
                />
              }
            />
            <FormControlLabel
              value="fonepay"
              control={<Radio />}
              label={
                <img
                  src={fonepay}
                  alt="fonepay logo payment selection"
                  style={{ height: "28px", width: "100px" }}
                />
              }
            />
            <FormControlLabel
              value="mobileBank"
              control={<Radio />}
              label={
                <img
                  src={mobBanking}
                  alt="mobile banking logo payment selection"
                  style={{ height: "18px", width: "100px" }}
                />
              }
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
}

export default CheckoutPayment;
