import React, { useState } from "react";
import { Box } from "@mui/material";
import HomeCard from "./card_main";
import Slider from "react-slick";
import foodData from "src/components/offerSection/dataItems";

function HomeFirstSection() {
  const [sliderIndex, setSliderIndex] = useState(0);
  const [ingredientAnimateClass, setIngredientAnimateClass] = useState("");


  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setSliderIndex(next),
  };

  return (
    <Box
      sx={{

        mt: { xs: 12, sm: 10, md: 12, lg: 10 },
        "&::before": {
          content: '""',
          position: "absolute",
          top: "-10px",
          right: "-10px",
          left: "-10px",
          filter: "blur(10px)",
          background: "inherit",
        },
      }}
    >
      <Slider {...settings}>
        {foodData.map((food) => (
          <HomeCard
            key={food.id}
            food={food}
            sliderIndex={sliderIndex}
            setIngredientAnimateClass={setIngredientAnimateClass}
            ingredientAnimateClass={ingredientAnimateClass}
          />
        ))}
      </Slider>
    </Box>
  );
}

export default HomeFirstSection;
