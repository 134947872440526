import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Typography, TextField, Grid } from "@mui/material";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";

function DeliveryAddress({
  formattedAddress: initialFormattedAddress,
  specialInstructions: initialSpecialInstructions,
  addressTitle: initialAddressTitle,
  phoneNumber: initialPhoneNumber,
  onDistanceCalculated,
  onAddressUpdate,
}) {
  const LIBRARIES = ["places"];
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_BBG_MAP_API_KEY,
    libraries: LIBRARIES,
  });

  const [locationText, setLocationText] = useState(initialFormattedAddress || "");
  const autocompleteRef = useRef(null);
  const [error, setError] = useState("");

  const updateAddressFromCoords = useCallback((coords) => {
    if (window.google.maps.Geocoder) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: coords }, (results, status) => {
        if (status === "OK" && results[0]) {
          setLocationText(results[0].formatted_address);
          onAddressUpdate(results[0].formatted_address);
        } else {
          setError("No address found for location.");
        }
      });
    }
  }, [onAddressUpdate]);

  useEffect(() => {
    if (navigator.geolocation && window.google) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const newPos = { lat: latitude, lng: longitude };
          updateAddressFromCoords(newPos); // Update the text field with the geocoded address
        },
        () => {
          setError("Unable to retrieve your location.");
        }
      );
    }
  }, [isLoaded, updateAddressFromCoords]); // Include updateAddressFromCoords in dependencies

  const handleManualLocationChange = (event) => {
    setLocationText(event.target.value);
  };

  const handleManualLocationUpdate = () => {
    const geocoder = new window.google.maps.Geocoder();
    if (locationText.trim() === "") return;

    geocoder.geocode({ address: locationText }, (results, status) => {
      if (status === "OK" && results[0]) {
        onAddressUpdate(results[0].formatted_address);
      } else {
        setError("Location not found.");
      }
    });
  };

  const onPlaceSelected = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place.geometry) {
        const newPos = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setLocationText(place.formatted_address);
        onDistanceCalculated(newPos);
        onAddressUpdate(place.formatted_address);
      }
    }
  };

  return (
    <Box sx={{ padding: "1rem" }}>
      <Box sx={{ mb: "1rem" }}>
        <Typography sx={{ mb: "1rem" }}>Address</Typography>
        {isLoaded && (
          <Autocomplete
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
            onPlaceChanged={onPlaceSelected}
          >
            <TextField
              label="Address *"
              value={locationText}
              onChange={handleManualLocationChange}
              onBlur={handleManualLocationUpdate}
              fullWidth
            />
          </Autocomplete>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </Box>

      <Box sx={{ mb: "1.5rem" }}>
        <Typography sx={{ mb: "1rem" }}>Your Information</Typography>
        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item xs={12} md={6}>
            <TextField id="first-name" label="First Name" required fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField id="last-name" label="Last Name" required fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField id="mobile-number" label="Mobile Number" required fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField id="email-address" label="Email Address" fullWidth />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: "1.5rem" }}>
        <TextField
          id="special-instructions"
          label="Food Instructions"
          multiline
          rows={2}
          fullWidth
          defaultValue={initialSpecialInstructions}
        />
      </Box>
      <Box sx={{ mb: "1.5rem" }}>
        <TextField
          id="address-instructions"
          label="Address Instructions"
          multiline
          rows={4}
          fullWidth
          defaultValue={initialAddressTitle}
        />
      </Box>
    </Box>
  );
}

export default DeliveryAddress;
