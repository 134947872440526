import React, { useState, useEffect } from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import DataSet from "src/components/offerSection/dataItems";
import { useLocation } from "react-router-dom";
import HeaderSection from './HeaderSection';
import GridLayout from './GridLayout';
import MobileSlider from './MobileSlider';

function FirstSection() {
  const location = useLocation();
  const { selectedItem } = location.state || {};

  const [selectedCategory, setSelectedCategory] = useState("Biryani");
  const [searchedItems, setSearchedItems] = useState([]);

  useEffect(() => {
    const filteredItemsFromDataSet = DataSet.filter(
      (item) => item.category === selectedCategory
    );
    setSearchedItems(filteredItemsFromDataSet);
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedItem) {
      const itemIndex = DataSet.findIndex(
        (item) => item.id === selectedItem.id
      );
      if (itemIndex !== -1) {
        const item = DataSet[itemIndex];
        setSelectedCategory(item.category);
        const updatedItems = [
          item,
          ...DataSet.filter((_, index) => index !== itemIndex),
        ];
        setSearchedItems(updatedItems);
      }
    }
  }, [selectedItem]);

  const categories = [...new Set(DataSet.map((item) => item.category))];

  return (
    <Box sx={{ padding: 1, mb: 10 }}>
      <HeaderSection
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        categories={categories}
      />

      {/* Conditionally render Slider on mobile and Grid on larger screens */}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileSlider searchedItems={searchedItems} />
      </Box>
      
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <GridLayout searchedItems={searchedItems} />
      </Box>

      {selectedCategory === "Momo" && (
        <Box
          sx={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            margin: "30px 0",
          }}
        >
          <FormControlLabel
            value="end"
            control={<Checkbox />}
            label="Jhol"
            labelPlacement="end"
          />
          <FormControlLabel
            value="end"
            control={<Checkbox />}
            label="Steam"
            labelPlacement="end"
          />
          <FormControlLabel
            value="end"
            control={<Checkbox />}
            label="Fried"
            labelPlacement="end"
          />
          <FormControlLabel
            value="end"
            control={<Checkbox />}
            label="C"
            labelPlacement="end"
          />
          <FormControlLabel
            value="end"
            control={<Checkbox />}
            label="Kothey"
            labelPlacement="end"
          />
        </Box>
      )}
    </Box>
  );
}

export default FirstSection;
