import React from "react";
import Slider from "react-slick";
import { Box, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import dataList from "./extraDataList";
import ExtraFoodCard from "./extraFoodCard";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      sx={{
        backgroundColor: "transparent",
        color: "black",
        position: "absolute",
        top: "50%",
        right: "-50px",
        transform: "translateY(-50%)",
        zIndex: 1,
      }}
    >
      <ChevronRight />
    </IconButton>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      style={{
        backgroundColor: "transparent",
        color: "black",
        position: "absolute",
        top: "50%",
        left: "-50px",
        transform: "translateY(-50%)",
        zIndex: 1,
      }}
    >
      <ChevronLeft />
    </IconButton>
  );
}

function ExtraFoodSlider() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Box
      sx={{
        padding: { xs: "10px 20px", md: "10px 50px" },
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Slider {...settings}>
        {dataList.map((item) => (
          <div key={item.id}>
            <ExtraFoodCard
              image={item.image}
              name={item.name}
              price={item.price}
            />
          </div>
        ))}
      </Slider>
    </Box>
  );
}

export default ExtraFoodSlider;
