import React from "react";
import {
  Card,
  Button,
  Box,
  CardMedia,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";

function ExtraFoodCard({ image, name, price }) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <CardActionArea sx={{ flex: 1 }}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            paddingTop: {
              xs: "75%",
              sm: "60%",
              md: "56.25%",
              lg: "50%",
              xl: "40%",
            },
          }}
        >
          <CardMedia
            component="img"
            image={image}
            alt="Food Image"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <CardContent>
          <Typography
            className="responsive_fontsize24"
            variant="h5"
            gutterBottom
          >
            {name}
          </Typography>
          <Typography
            className="responsive_fontsize20"
            variant="body1"
            sx={{ position: "relative", display: "inline-block" }}
          >
            <span
              style={{
                textDecoration: "line-through",
                textDecorationColor: "red",
              }}
            >
              {price} 
            </span>
            <span>  NRS 80</span>
          </Typography>
          <Typography>Till 9PM</Typography>
          <Button
            className="responsive_fontsize16"
            variant="contained"
            sx={{
              paddingY: 1,
              position: "absolute",
              bottom: 14,
              right: 8,
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "#000000",
              height: "1.5rem",
              backgroundColor: "#0ff34f",
              "&:hover": {
                backgroundColor: "#8e5a00",
              },
            }}
          >
            Get Now
          </Button>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ExtraFoodCard;
