import React from 'react';
import { Box, Button } from '@mui/material';

const PaginationControls = ({
  flattenedItemsToShow,
  displayedItems,
  itemsPerPage,
  handleMoreClick,
  handleLessClick,
}) => (
  <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
    {flattenedItemsToShow.length > displayedItems.length && (
      <Button
        variant="outlined"
        className="responsive_fontsize18"
        onClick={handleMoreClick}
        sx={{ mr: 2 }}
      >
        See More
      </Button>
    )}
    {displayedItems.length > itemsPerPage && (
      <Button
        variant="outlined"
        className="responsive_fontsize18"
        onClick={handleLessClick}
      >
        Show Less
      </Button>
    )}
  </Box>
);

export default PaginationControls;
