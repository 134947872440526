import React from "react";
import Slider from "react-slick";
import { Box } from "@mui/material";
import FoodCard from "../FoodCard/FoodCard";

const SliderComponent = ({ items }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Slider {...settings}>
        {items.map((item, index) => (
          <Box key={`${item.id}-${index}`} sx={{ padding: "2rem" }}>
            <FoodCard
              fooditem={item}
              subcategory={
                item.subcategory.length > 0 ? item.subcategory[0] : ""
              }
              // quantity={0}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default SliderComponent;
