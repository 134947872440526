// SearchBox Component for inputs
import React from "react";
import { Autocomplete } from "@react-google-maps/api";
import { Grid, TextField } from "@mui/material";
const SearchBoxComponent = ({ originRef, destinationRef, locationText, handleLocationChange, handleLocationUpdate }) => (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Autocomplete>
          <TextField
            id="origin"
            label="Your Location"
            variant="outlined"
            size="small"
            inputRef={originRef}
            value={locationText}
            onChange={handleLocationChange}
            onBlur={handleLocationUpdate}
            fullWidth
          />
        </Autocomplete>
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete>
          <TextField
            id="destination"
            label="Destination"
            variant="outlined"
            inputRef={destinationRef}
            size="small"
            fullWidth
          />
        </Autocomplete>
      </Grid>
    </Grid>
  );

  export default SearchBoxComponent;