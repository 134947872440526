import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Button,
  Avatar,
  Menu,
  MenuItem,
  useScrollTrigger,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../assets/logo.jpg";
import OrderListDrawer from "src/components/order_list/order_list";
import NotificationList from "../../components/notification_alert/notification_list_data";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const drawerWidth = 280;

const navItems = [
  {
    id: 1,
    name: "Subscription",
    to: "/subscription",
  },
  {
    id: 2,
    name: "Make Your Menu",
    to: "/make-your-menu",
  },
  {
    id: 3,
    name: "Menu",
    to: "/menu",
  },
  {
    id: 4,
    name: "Veg",
    to: "/veg",
  },
  {
    id: 5,
    name: "Non-veg",
    to: "/nonveg",
  },
  {
    id: 6,
    name: "Book-Your-Table",
    to: "/book-your-table",
  },
];

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    style: {
      boxShadow: trigger ? "0px 2px 3px -1px gray" : "none",
      backgroundColor: trigger ? "#FFFFFF" : "#FCF8F8",
    },
  });
}

const DrawerAppBar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [avatarAnchorEl, setAvatarAnchorEl] = useState(null);
  const [orderListOpen, setOrderListOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const isLoggedIn = useSelector((state) => state.isLoggedIn); // Get login state from Redux store
  const cartItems = useSelector((state) => state.cart.items.length);

  // Get orderConfirmed state
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setActiveItem("");
    }
  }, [location.pathname]);

  const handleProfilePage = () => {
    navigate("/profile");
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleOrderListOpen = () => {
    if (cartItems === 0) {
      toast.warning(
        "Your cart is currently empty. Please add items to your cart before proceeding to checkout."
      );
    } else {
      setOrderListOpen(true);
    }
  };

  const handleOrderListClose = () => {
    setOrderListOpen(false);
  };

  const handleNotificationOpen = () => {
    setNotificationOpen(true);
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handleAvatarClick = (event) => {
    setAvatarAnchorEl(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarAnchorEl(null);
  };

  const handleLoginClick = () => {
    navigate("/login"); // Navigate to login page
  };
  const handleNavItemClick = (to) => {
    setActiveItem(to);
    navigate(to);
    if (mobileOpen) {
      setMobileOpen(false);
    }
  };
  // Listen to changes in cartItems and close order list drawer if cart becomes empty
  React.useEffect(() => {
    if (cartItems === 0) {
      setOrderListOpen(false);
    }
  }, [cartItems]);

  const drawer = (
    <Box sx={{ padding: "1rem" }}>
      <Box
        sx={{
          flexGrow: 1,
          ml: { xl: "4.3rem", lg: "3rem", xs: "1rem" },
          display: "flex",
          alignItems: "flex-end",
          gap: "1rem",
        }}
      >
        <Avatar
          alt="BBG Logo"
          src={logo}
          sx={{
            width: "3.5rem",
            height: "3.5rem",
          }}
        />
        <Typography
          className="responsive_fontsize20"
          sx={{
            fontWeight: "400",
            color: "#166F2A",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          Welcome to BBG
        </Typography>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton
              sx={{
                textAlign: "left", // Align text to the left
                pl: 2, // Add padding to the left for alignment
                width: "100%", // Ensure full width
                backgroundColor:
                  activeItem === item.to ? "#FFB800" : "transparent",
                color: activeItem === item.to ? "#000000" : "#000000",
              }}
              onClick={() => handleNavItemClick(item.to)}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Button onClick={handleLoginClick} sx={{ alignItems: "center" }}>
        Login
      </Button>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <nav>
      <ElevationScroll {...props}>
        <AppBar
          sx={{
            height: "6rem",
            // backgroundColor: "#FCF8F8",
            top: "0",
            width: "100%",
          }}
        >
          <Toolbar sx={{ height: "100%" }}>
            <Box
              sx={{
                flexGrow: 1,
                ml: { xl: "4.3rem", lg: "3rem", xs: "1rem", sm: "1rem" },
                mr: { xl: "4.3rem", lg: "3rem", xs: "1rem", sm: "1rem" },
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                justifyContent: "flex-start",
              }}
            >
              <Link to="/" replace={true} style={{ textDecoration: "none" }}>
                <Avatar
                  alt="BBG Logo"
                  src={logo}
                  sx={{ width: "4.375rem", height: "4.375rem" }}
                />
              </Link>
              <Link to="/" replace={true} style={{ textDecoration: "none" }}>
                <Typography
                  className="responsive_fontsize28"
                  sx={{
                    fontSize: "1.375rem",
                    fontWeight: "400",
                    color: "#166F2A",
                  }}
                >
                  Welcome to BBG
                </Typography>
              </Link>
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleOrderListOpen}
              sx={{ mr: 2, display: { xs: "flex", md: "none" } }}
            >
              <Badge badgeContent={cartItems} color="primary">
                <ShoppingCartIcon sx={{ color: "#281010" }} />
              </Badge>
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "space-around",
                mr: { xl: "4.3rem", lg: "3rem", xs: "1rem" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: "2rem",
                  // gap: "2rem",
                }}
              >
                {navItems.map((item) => (
                  <Button
                    key={item.id}
                    onClick={() => handleNavItemClick(item.to)}
                    sx={{
                      color: activeItem === item.to ? "#FFB800" : "#000000",
                      fontWeight: "normal",
                    }}
                  >
                    {item.name}
                  </Button>
                ))}
              </Box>

              <Box sx={{ display: "flex", gap: "1rem" }}>
                <IconButton onClick={handleOrderListOpen}>
                  <Badge badgeContent={cartItems} color="primary">
                    <ShoppingCartIcon sx={{ color: "#281010" }} />
                  </Badge>
                </IconButton>
                <IconButton onClick={handleNotificationOpen}>
                  <NotificationsIcon sx={{ color: "#281010" }} />
                </IconButton>
                {isLoggedIn ? (
                  <>
                    <IconButton onClick={handleAvatarClick}>
                      <Avatar alt="User Avatar" src="/path/to/avatar.jpg" />
                    </IconButton>
                    <Menu
                      anchorEl={avatarAnchorEl}
                      open={Boolean(avatarAnchorEl)}
                      onClose={handleAvatarClose}
                    >
                      <MenuItem onClick={handleProfilePage}>Profile</MenuItem>
                      <MenuItem onClick={handleAvatarClose}>Logout</MenuItem>
                    </Menu>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#281010",
                      color: "white",
                      width: "100%",
                      fontSize: "1rem",
                      fontFamily: "Roboto Serif",
                      padding: "0.2rem 1.2rem",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#1f0e0e",
                      },
                    }}
                    startIcon={<PersonAddAlt1Icon />}
                    onClick={handleLoginClick}
                  >
                    Login
                  </Button>
                )}
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Drawer
        container={container}
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>

      <Drawer
        anchor="right"
        open={orderListOpen}
        onClose={handleOrderListClose}
        ModalProps={{
          keepMounted: true,
        }}
        BackdropProps={{
          invisible: true,
        }}
        sx={{
          display: "block",
          justifyContent: "flex-end",
          "& .MuiDrawer-paper": {
            top: "6.25rem",
            right: 0,
            position: "fixed",
            boxSizing: "border-box",
            width: {
              xs: 340,
              sm: 380,
              md: 480,
            },
            height: "auto",
            background: "#FFB800",
            margin: {
              xs: "0 1rem 0 0",
              sm: "0 2.2rem 0 0",
            },
            padding: "0.5rem",
          },
        }}
      >
        <OrderListDrawer onClose={handleOrderListClose} />
      </Drawer>

      <Drawer
        anchor="right"
        open={notificationOpen}
        onClose={handleNotificationClose}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: "block",
          justifyContent: "flex-end",
          "& .MuiDrawer-paper": {
            top: "6.25rem",
            right: 0,
            height: "auto",
            position: "fixed",
            boxSizing: "border-box",
            width: {
              xs: 350,
              sm: 390,
              md: 490,
            },
            margin: {
              xs: "0 1rem 0 0",
              sm: "0 2.2rem 0 0",
            },
          },
        }}
      >
        <NotificationList onClose={handleNotificationClose} />
      </Drawer>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </nav>
  );
};

export default DrawerAppBar;
